export const WEB_CHECKOUT_AR = {
  labels: {
    loanExpiresIn: "تنتهي صلاحية التمويل خلال",
  },
  steps: {
    phoneVerification: "اﻟﺘﺤﻘﻖ ﺑﺎﻟﻬﺎﺗﻒ",
    contractSigned: " ﺗﻮﻗﻴﻊ اﻟﻌﻘﺪ",
    ePromissoryNote: "ﻗﺒﻮل اﻟﺴﻨﺪ اﻟﺎذﻧﻲ",
    loanActivated: "ﺗﻔﻌﻴﻞ اﻟﻘﺮض",
  },
  title: {
    greetings: "اهلا {{name}}",
    waitPhoneCall: "ﻣﻦ ﺟﻬﺘﻨﺎ ﻣﻦ ﻓﻀﻠﻚ اﻧﺘﻈﺮ ﻣﻜﺎﻟﻤﻪ",
    contractReady: "اﻟﻌﻘﺪ ﺟﺎﻫﺰ ﻟﻠﺘﻮﻗﻴﻊ",
    ePromissory: "يرجى قبول السند الإذني",
    loanIsActivated: "ﻣﺒﺮوك!",
    orderCanceled: "نأسف! ﺗﻢ اﻟﻐﺎء اﻟﻄﻠﺐ",
    orderRejected: "تم رفض العقد",
    didntMeetQuaraCriteria: "للتمويل نأسف! بياناتك المالية لا تتوافق مع متطلبات كوارا",
    contractExpired: "انتهت صلاحيه العقد",
    orderCompleted: "تم اكتمال الطلب بنجاح",
    missedPayment: "لديك قسط متأخر",
    downloadWithBenefits: "قم بنزيل تطبيق ٩٩ واحصل على مميزاتنا",
    contractName: "ﻋﻘﺪ ﺗﻤﻮﻳﻞ اﻟﻄﻠﺐ رﻗﻢ {{contractNumber}}",
  },
  content: {
    contractIntroducing:
      "نسعد لاختيارك خدماتنا! لقد تقدمت بطلب تمويل، ويُمكنك إكمال خطواتك على الموقع أو تحميل التطبيق لمتابعة خطوات توقيع العقد.",
    phoneConfirmation: "سنقوم بتحديد الخطوات القادمة عبر محادثتنا، وسيتم بعدها تحويلك إلى مرحلة توقيع العقد.",
    fullAgreementVersion: "يرجى التحقق ﻣﻦ ﻛﻞ ﺗﻔﺎﺻﻴﻞ اﻟﻌﻘﺪ ﻗﺒﻞ اﻟﺘﻮﻗﻴﻊ",
    confirmContract:
      "أُقر بأن جميع البيانات صحيحة ومُحدّثة وأسمح لشركة كوارا للتمويل بإرسال هذه البيانات إلى سمة بهدف التحقق من الحد الائتماني للتمويل و الموافقة على شروط العقد تعد توقيع للعقد.",
    nafithInstruction:
      "ﺑﻌﺪ اﻟﻀﻐﻂ ﻋﻠﻰ اﻟرسالة ﻣﻦ ﻧﺎﻓﺬ ﺳﻮف ﺗﺘﻮﺟﻪ إلى ﻣﻮﻗﻊ ﻧﺎﻓﺬ ﺣﻴﺚ ﻳﻤﻜﻨﻚ الموافقة ﻋﻠﻰ اﻟﺴﻨﺪ الأذني وﺗﻔﻌﻴﻞ التمويل",
    storeInformedAboutActivation:
      "سوف يتم تفعيل التمويل خلال 24 ساعة. ﻳﻤﻜﻨﻚ ﺗﺤﻤﻴﻞ ﺗﻄﺒﻴﻖ جاك ﻟﺘﺴﻬﻴﻞ إدارة ومتابعة أقساطك",
    orderCanceled:
      "مازال يمكنك شراء ماتحتاجه معنا. من فضلك قم بزياره شركائنا واشتري من خلال كوارا ٩٩. لمعلومات اكثر من فضلك تواصل معنا علي :<0>{{email}}</0>",
    orderRejected: "نأسف ﻟﺮﻓﻀﻚ اﻟﻌﻘﺪ, ﻣﻦ ﻓﻀﻠﻚ قم بزيارة ﺷﺮﻛﺎﺋﻨﺎ وﺗﻤﺘﻊ بمزايا اﻟﺸﺮاء ﻣﻌﻨﺎ",
    didntMeetQuaraCriteria: "ﻣﻦ ﻓﻀﻠﻚ ﺗﻮاﺻﻞ ﻣﻌﻨﺎ ﻟﻤﻌﺮﻓﻪ اﻟﻤﺰﻳﺪ ﻋﻠﻰ <0>{{email}}</0>",
    contractExpired: "للأسف، انتهى الوقت المُحدد لتوقيع العقد. تفضل بزيارة شركائنا والحصول على مزايا الدفع من خلالنا.",
    orderCompleted: "شكرا لسداد كل الاقساط المستحقه. من فضلك قم بزياره شركائنا لشراء المزيد من خلال كوارا ٩٩.",
    missedPayment: "من فضلك افتح تطبيق ٩٩ لتسدد القسط المتأخر.",
    downloadWithBenefits: "تتبع طلباتك,تابع اقساطك, واعرض كل شركائنا من مكان واحد",
    reviewContract: "راجع و وقع العقد التالي",
    redirectionMSG: "سيتم توجيهك بعد {{variable}} ثوان ...",
  },
};
