import { Box, Divider, Grid, useMediaQuery, useTheme } from "@mui/material";
import { Button, COLORS, Typography, WarningBlock } from "@ui-kit";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import AppUI from "@assets/uiImages/app_ui.png";
import sandClockGif from "@assets/uiImages/sand_clock_animation.gif";
import { MobileDialog } from "@components/Dialogs/BasicDialog";
import { ResponsiveImage } from "@components/ResponsiveImage/ResponsiveImage";
import { additionalContentCodes } from "@components/TopMenu/TopMenu.constants";
import { HeaderWithStatusImg } from "@components/TopMenu/components/customHeaders/HeaderWithStatusImg";
import { ORDER_STATUSES } from "@constants/business/orderStatuses";
import { STORAGE_DEFAULT_CONTRACT } from "@constants/sessionStorageDefaults";
import { CONTRACT_DATA_KEY } from "@constants/sessionStorageKeys";
import { siteMap } from "@constants/siteMap";
import { menuService } from "@store/menu";
import { useDialog } from "@utils/hooks/useDialog";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { E_PROMISSORY_NOTE_STATE } from "@utils/network/contractInfo/types";

import {
  AppInfoTextWrapper,
  FurtherInfoContainer,
  Header,
  ImageWrapper,
  ResponsiveAppLogo,
  StyledAppInfoContainer,
  StyledWrapper,
} from "./Confirmation.components";

interface ILocationState {
  hasMultipleOpenContracts: boolean;
  orderStatus: ORDER_STATUSES;
}

const getErrorByContractStatus = (status: ORDER_STATUSES): string => {
  switch (status) {
  case ORDER_STATUSES.WAITING_CONFIRMATION_CALL: {
    return "confirmationContent.warnings.contractIsWaitingForACall";
  }
  case ORDER_STATUSES.WAITING_FOR_SIGNING: {
    return "confirmationContent.warnings.contractIsWaitingForASigning";
  }
  case ORDER_STATUSES.SIGNED: {
    return "confirmationContent.warnings.contractIsSigned";
  }

  default: {
    return "confirmationContent.warnings.contractIsWaitingForACall";
  }
  }
};

export const Confirmation: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const [isAppDialogOpen, closeMobileDialog, openMobileDialog] = useDialog();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const state = location.state as ILocationState;
  const { hasMultipleOpenContracts, orderStatus } = state || {};
  const { t } = useTranslation();
  const [contractData] = useSessionStorageState(CONTRACT_DATA_KEY, STORAGE_DEFAULT_CONTRACT);

  useEffect(() => {
    if (hasMultipleOpenContracts) {
      menuService.setMenuData({
        leftSide: getErrorByContractStatus(orderStatus),
        rightSide: "",
        needIcon: false,
      });
      menuService.setAdditionalContentInfo({
        isAdditionalContentNeeded: isMobile,
        additionalContentCode: additionalContentCodes.WARNING_HEADER,
      });
    } else {
      menuService.setMenuData({ leftSide: "confirmationContent.header", rightSide: "", needIcon: false });
      menuService.setAdditionalContentInfo({
        isAdditionalContentNeeded: isMobile,
        additionalContentCode: additionalContentCodes.HEADER_WITH_STATUS_IMG,
      });
    }

    return () => {
      menuService.setAdditionalContentInfo({
        isAdditionalContentNeeded: false,
        additionalContentCode: "",
      });
    };
  }, [isMobile]);

  const handleContinueClick = () => {
    history.push(siteMap.InitialStep.path);
  };

  const handleContinueWithApp = () => {
    openMobileDialog();
  };

  return (
    <>
      <Box>
        {!isMobile && (
          <Header container>
            {hasMultipleOpenContracts ? (
              <WarningBlock warningText={t(getErrorByContractStatus(orderStatus))} mb="16px" />
            ) : null}
            <img alt="sandClock" src={sandClockGif} />
            <Typography
              variant="p1"
              component="p"
              textAlign="center"
              margin={"24px 0 16px"}
              fontWeight={600}
              fontSize={isMobile ? "1.6rem" : "2rem"}
            >
              {t("confirmationContent.header")}
            </Typography>
          </Header>
        )}
        {isMobile && hasMultipleOpenContracts && (
          <HeaderWithStatusImg headerText={t("confirmationContent.header")} insideConfirmationComponent />
        )}
        <FurtherInfoContainer>
          <StyledWrapper item>
            <Typography fontWeight="600" color={COLORS.DARK_TEAL} marginBottom="4px">
              {t("confirmationContent.whatWillHappenNext")}
            </Typography>
            <ul>
              <li>{t("confirmationContent.phoneCall")}</li>
              <li>{t("confirmationContent.message")}</li>
              <li>{t("confirmationContent.contract")}</li>
              {contractData.ePromissoryNoteState !== E_PROMISSORY_NOTE_STATE.NOT_NEED && (
                <li>{t("confirmationContent.nafithConfirm")}</li>
              )}
              <li>{t("confirmationContent.transaction")}</li>
            </ul>
          </StyledWrapper>
        </FurtherInfoContainer>
        <Box mb="10px" mt="10px">
          <Divider />
        </Box>
        <StyledAppInfoContainer container>
          <AppInfoTextWrapper
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            container
          >
            <ResponsiveAppLogo />
            <Typography component="p" margin="0" fontSize={isMobile ? "1.4rem" : "1.6rem"}>
              {t("confirmationContent.installMobileApp")}
            </Typography>
          </AppInfoTextWrapper>
          <ImageWrapper
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            container
          >
            <ResponsiveImage src={AppUI} alt="app interface" />
          </ImageWrapper>
        </StyledAppInfoContainer>
        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          display="flex"
          justifyContent="space-between"
          marginTop="40px"
          paddingBottom={isMobile ? "35px" : 0}
          maxWidth="100%"
        >
          <Button
            tertiary
            sx={{
              padding: { xs: 0 },
              fontSize: { xs: "1.6rem" },
              minWidth: { md: "240px", xs: "150px" },
            }}
            onClick={handleContinueWithApp}
            color={!isMobile ? "secondary" : "inherit"}
          >
            {t("buttons.continueWithApp") as string}
          </Button>
          <Button
            sx={{
              fontSize: { xs: "1.6rem" },
              padding: { xs: 0 },
              minWidth: { sm: "240px", xs: "150px" },
            }}
            onClick={handleContinueClick}
          >
            {t("buttons.continueHere") as string}
          </Button>
        </Grid>
        <MobileDialog isOpen={isAppDialogOpen} onClose={closeMobileDialog} />
      </Box>
    </>
  );
};
