import { Switch, Route } from "react-router-dom";

import { ContentLayout } from "@components/ContentLayout";
import { LoaderProvider } from "@components/LoaderProvider";
import { OrderInfo } from "@components/OrderInfo";
import { TopMenu } from "@components/TopMenu";
import { siteMap } from "@constants/siteMap";
import { Error404Page } from "@pages/Error404Page";

import { MainContentRouter } from "./MainContentRouter";

export const AppRouter: React.FC = () => {
  return (
    <Switch>
      <Route path={siteMap.Error404Page.path}>
        <Error404Page />
      </Route>
      <Route path={siteMap.HomePage.path}>
        <LoaderProvider>
          <TopMenu />
          <ContentLayout mainContent={<MainContentRouter />} additionalContent={<OrderInfo />} />
        </LoaderProvider>
      </Route>
    </Switch>
  );
};
