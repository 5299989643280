import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { noop } from "rxjs";

import { IDialogWithHandler } from "@appTypes/dialogs";
import { SetOTPComponent } from "@components/SetOTP";
import { RELOAD_KEY, RESEND_OTP_TIMER_KEY } from "@constants/sessionStorageKeys";
import { siteMap } from "@constants/siteMap";
import { useDialog } from "@utils/hooks/useDialog";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";

import { somethingWentWrongDialog } from "./SetKYC.constants";
import { kycDialogs, KYC_VERIFICATION_DIALOGS } from "./SetKYC.types";
import { KYCDialogs } from "./components/KYCDialogs";
import { useGenerateKYCCode } from "./hooks/useGenerateKYC";
import { useVerifyKYCCode } from "./hooks/useVerifyKYC";

export const SetKYC: FC = () => {
  const [isDialogOpen, closeDialog, openDialog] = useDialog();
  const { t } = useTranslation();
  const history = useHistory();

  const [isPageReloaded, setReloadFlag, removeReloadFlag] = useSessionStorageState<boolean>(RELOAD_KEY, false);
  const [resendClickTime, setResendClickTime] = useState<string>();
  const [, setResendTimerDateToStorage] = useSessionStorageState(RESEND_OTP_TIMER_KEY, {
    resendClickDate: "",
  });
  const [dialogInfo, setDialogInfo] = useState<IDialogWithHandler<kycDialogs, { blockedUntil?: string }>>();

  const { generateKYCCode } = useGenerateKYCCode();
  const { verifyKYCCode } = useVerifyKYCCode();

  const generateKYC = async () => {
    try {
      const { isSuccess, ...dialogData } = await generateKYCCode();

      if (!isSuccess) {
        setDialogInfo({ ...dialogData, lastAction: generateKYC });
        openDialog();

        return { isResendSuccess: false };
      }
      setReloadFlag(true);
      const requestDate = new Date().toString();

      await setResendTimerDateToStorage({ resendClickDate: requestDate });
      setResendClickTime(requestDate);
      return { isResendSuccess: true, requestAt: dialogData.dialogOptions?.requestAt };
    } catch (e) {
      setDialogInfo({ ...somethingWentWrongDialog, lastAction: generateKYC });
      openDialog();

      return { isResendSuccess: false };
    }
  };

  const handleContinue = useCallback(async (otpInputValue, setInputError, setOtpMessage): Promise<void> => {
    const { isSuccess, ...dialogData } = await verifyKYCCode(otpInputValue);

    if (!isSuccess && dialogData.dialogType === KYC_VERIFICATION_DIALOGS.OTP_FAILED) {
      setInputError(true);
      setOtpMessage("errors.WRONG_OTP");
    } else if (!isSuccess) {
      setDialogInfo({ ...dialogData, lastAction: generateKYC });
      openDialog();
    } else if (isSuccess) {
      history.push(siteMap.SetupPasswordPage.path);
    }
  }, []);

  const handleCloseDialog = useCallback(() => {
    setDialogInfo({});
    closeDialog();
  }, [closeDialog]);

  useEffect(() => {
    if (!isPageReloaded) {
      generateKYC();
    }

    return () => {
      removeReloadFlag();
    };
  }, []);

  return (
    <>
      <SetOTPComponent
        title={"pageHeader.codeVerification"}
        subtitle={t("pageContent.codeSentTo")}
        handleResendKYC={generateKYC}
        pinLength={6}
        isKYC
        handleContinue={handleContinue}
        resendClickTime={resendClickTime}
        dialogMethods={{
          openUnexpectedErrorDialog: noop,
          setCurrentDialogContent: noop,
          openDialog: noop,
          setDialogType: noop,
        }}
      />
      <KYCDialogs
        {...dialogInfo}
        isDialogOpen={isDialogOpen}
        closeDialog={handleCloseDialog}
        openDialog={openDialog}
      />
    </>
  );
};
