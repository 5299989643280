import { OK, NOT_FOUND } from "@constants/httpStatuses";
import { IAxiosResponseWithError, network } from "@utils/network/network";

export enum CALL_STATUSES {
  OK = "OK",
  REJECTED = "REJECTED",
  WAIT = "WAIT",
  COMPLETED = "COMPLETED",
}

export enum LOCAL_CALL_STATUSES {
  CALL_NOT_CREATED = "CALL_NOT_CREATED",
}

export type CallStatysesType = CALL_STATUSES | LOCAL_CALL_STATUSES;

export interface ICallStatusResponse<A = undefined> {
  callbackAvailableAt: string;
  result: CALL_STATUSES | A;
}
export interface ICallStatusDialogInfo {
  isSuccess: boolean;
  data?: ICallStatusResponse<LOCAL_CALL_STATUSES | undefined>;
}

type useCallStatusReturnType = {
  requestCallStatus: () => Promise<ICallStatusDialogInfo>;
};

export const useCallStatus = (): useCallStatusReturnType => {
  const fetchCallStatus = (): Promise<IAxiosResponseWithError<ICallStatusResponse>> => {
    return network.request<ICallStatusResponse>("order", "customer/autocall/status", {
      method: "GET",
    });
  };

  const requestCallStatus = async (): Promise<ICallStatusDialogInfo> => {
    const { status: httpStatus, data } = await fetchCallStatus();

    switch (httpStatus) {
    case OK: {
      return {
        isSuccess: true,
        data,
      };
    }
    case NOT_FOUND: {
      return {
        // 404 means that call request wasn't created on BE side so it's kind of success ¯\_(ツ)_/¯
        isSuccess: true,
        data: { ...data, result: LOCAL_CALL_STATUSES.CALL_NOT_CREATED },
      };
    }
    default: {
      return {
        isSuccess: false,
      };
    }
    }
  };

  return { requestCallStatus };
};
