// TODO: SESSION_STORAGE - check using of REGISTRATION_KEY - this key should store only user registration data, not data about user status
export const REGISTRATION_KEY = "registration";
export const AUTHENTICATION_KEY = "userData";
export const RESET_PASSWORD_KEY = "resetPassword";
export const RELOAD_KEY = "isPageReloaded";
export const MERCHANT_DATA_KEY = "merchantData";
export const ORDER_DATA_KEY = "orderData";
export const OFFER_DATA_KEY = "offerData";
export const OFFER_PROVIDED_TIME = "OFFER_PROVIDED_TIME";
export const CONTRACT_DATA_KEY = "contractData";
export const RESEND_OTP_TIMER_KEY = "resendTimerDate";
export const SECTOR_INFO_KEY = "sector_info";
export const USER_INFO_KEY = "user_info";
