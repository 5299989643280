import { Divider, FormControlLabel, FormGroup, Grid, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { Button, CustomCheckbox } from "@ui-kit";
import { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import * as httpCodes from "@constants/httpStatuses";
import { useDialog } from "@utils/hooks/useDialog";

import { webCheckoutPageMap } from "..";
import { ConfirmationDialog } from "./components/ConfirmationDialog";
import { Contract } from "./components/Contract";
import { ErrorDialog } from "./components/ErrorDialog";
import { rejectContractRequest } from "./network/rejectContract";
import { signContractRequest } from "./network/signContract";

const REJECT_ERROR = "REJECT_ERROR";
const SIGN_ERROR = "SIGN_ERROR";

export const SignContract: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [signedFlag, setSignedFlag] = useState(false);
  const [contractHasError, setErrorFlag] = useState(false);
  const [isLoading, setLoadingFlag] = useState(true);
  const [isConfirmationDialogOpen, closeConfirmationDialog, openConfirmationDialog] = useDialog();
  const [isErrorDialogOpen, closeErrorDialog, openErrorDialog] = useDialog();
  const [errorType, setErrorType] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSignedFlag(event.target.checked);
  };

  const handleRejectClick = () => {
    openConfirmationDialog();
  };

  const handleSignContract = async () => {
    try {
      const { status } = await signContractRequest();

      if (status === httpCodes.OK) {
        history.push(webCheckoutPageMap.EPromissory.path);
      } else {
        closeConfirmationDialog();
        openErrorDialog();
        setErrorType(SIGN_ERROR);
      }
    } catch (e) {
      closeConfirmationDialog();
      openErrorDialog();
      setErrorType(SIGN_ERROR);
    }
  };

  const handleContractLoadingError = useCallback(() => {
    setLoadingFlag(false);
    setErrorFlag(true);
  }, [setErrorFlag]);
  const handleLoadingSuccess = useCallback(() => {
    setErrorFlag(false);
    setLoadingFlag(false);
  }, []);

  const handleRejectContract = async () => {
    try {
      const { status } = await rejectContractRequest();

      if (status === httpCodes.OK) {
        history.push(webCheckoutPageMap.DeclinedByUser.path);
      } else {
        closeConfirmationDialog();
        openErrorDialog();
        setErrorType(REJECT_ERROR);
      }
    } catch (e) {
      closeConfirmationDialog();
      openErrorDialog();
      setErrorType(REJECT_ERROR);
    }
  };

  const retryHandler = useMemo(
    () => (errorType === REJECT_ERROR ? handleRejectContract : handleSignContract),
    [errorType],
  );

  return (
    <>
      <Box>
        <Contract onLoadingError={handleContractLoadingError} onLoadingSuccess={handleLoadingSuccess} />
        <Box mt="40px">
          <Divider />
        </Box>
        <Box mt="40px">
          <FormGroup>
            <FormControlLabel
              control={
                <CustomCheckbox
                  disabled={isLoading || contractHasError}
                  checked={signedFlag}
                  onChange={handleCheckboxChange}
                  name="option1"
                />
              }
              label={t("checkout.content.confirmContract")}
            />
          </FormGroup>
        </Box>
        <Box display="flex" mt="24px">
          <Grid container justifyContent="space-between">
            <Grid item>
              <Button
                onClick={handleRejectClick}
                tertiary
                sx={{ minWidth: { md: "240px", xs: "150px" } }}
                color={!isMobile ? "secondary" : "inherit"}
              >
                {t("buttons.reject") as string}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleSignContract}
                disabled={!signedFlag || contractHasError || isLoading}
                sx={{ minWidth: { md: "240px", xs: "150px" } }}
              >
                {t("buttons.sign") as string}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <ConfirmationDialog
        isDialogOpen={isConfirmationDialogOpen}
        closeDialog={closeConfirmationDialog}
        onAgreeClick={handleRejectContract}
        onCancelClick={closeConfirmationDialog}
      />
      <ErrorDialog isDialogOpen={isErrorDialogOpen} closeDialog={closeErrorDialog} onAgreeClick={retryHandler} />
    </>
  );
};
