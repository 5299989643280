import { Grid, Box, useMediaQuery, useTheme } from "@mui/material";
import { Typography, Button } from "@ui-kit";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { noop } from "rxjs";

import { IDialogInfo } from "@appTypes/dialogs";
import { ReactComponent as CallManager } from "@assets/uiImages/call_manager.svg";
import { Dialog } from "@components/Dialogs/BasicDialog";
import { StepperArabic } from "@components/StepperArabic";
import { ORDER_STATUSES } from "@constants/business/orderStatuses";
import { useCustomerLanguage } from "@utils/hooks/useCheckCustomerLanguage/useCustomerLanguage";
import { useCountdown } from "@utils/hooks/useCountdown/useCountdown";
import { useDialog } from "@utils/hooks/useDialog";
import { useGetContractInfoPooling } from "@utils/network/contractInfo/useGetCustomerInfoPooling";
import { COUNTDOWN_TIMES, getTimeBefore } from "@utils/services/Timer/Timer.service";

import { useStatusRouter } from "../hooks/useStatusRouter";
import { useSteps } from "../hooks/useSteps";
import { useCallStatus } from "./hooks";
import { useCallRequest, CALLBACK_DIALOG_TYPES } from "./hooks/useCallRequest";
import { CALL_STATUSES, LOCAL_CALL_STATUSES, CallStatysesType } from "./hooks/useCallStatus";

const AUTO_CALL_IS_ENABLED = true;
const POLLING_TIMEOUT = COUNTDOWN_TIMES["2min"];

export const WaitingConfirmation: FC = () => {
  const { t } = useTranslation();
  const { onGetContractStatus } = useStatusRouter(ORDER_STATUSES.WAITING_CONFIRMATION_CALL);
  const { requestCall } = useCallRequest();
  const { requestCallStatus } = useCallStatus();
  const { getCustomerLanguage } = useCustomerLanguage();
  const onErrorPooling = () => true;
  const { startContractInfoPolling, stopPolling } = useGetContractInfoPooling();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { countdown, isExpired, startCountdown } = useCountdown();
  const [isDialogOpen, closeDialog, openDialog] = useDialog();
  const [dialogInfo, setDialogInfo] = useState<IDialogInfo<CALLBACK_DIALOG_TYPES> | null>(null);

  const steps = useSteps();

  const handleCallStatus = useCallback(async (status?: CallStatysesType) => {
    switch (status) {
    case LOCAL_CALL_STATUSES.CALL_NOT_CREATED: {
      await requestNewCall(true);
      startContractInfoPolling(onGetContractStatus, onErrorPooling, POLLING_TIMEOUT);
      break;
    }
    case CALL_STATUSES.OK:
    case CALL_STATUSES.COMPLETED:
    case CALL_STATUSES.WAIT:
    case CALL_STATUSES.REJECTED: {
      startContractInfoPolling(onGetContractStatus, onErrorPooling, POLLING_TIMEOUT);
      break;
    }
    }
  }, []);

  const checkCallStatus = useCallback(async () => {
    const { isSuccess, data } = await requestCallStatus();
    if (isSuccess == true) {
      if (!data) {
        return;
      }
      const { result, callbackAvailableAt } = data;
      const timeToNextCallRequest = getTimeBefore(callbackAvailableAt);
      timeToNextCallRequest && startCountdown(timeToNextCallRequest);
      handleCallStatus(result);
    }
  }, [requestCallStatus]);

  const requestNewCall = useCallback(async (silent = false) => {
    const language = getCustomerLanguage();
    const { isSuccess, data, ...dialogData } = await requestCall(language);

    if (isSuccess == true) {
      if (!data) {
        return;
      }
      const { callbackAvailableAt } = data;
      const timeToNextCallRequest = getTimeBefore(callbackAvailableAt);
      timeToNextCallRequest && startCountdown(timeToNextCallRequest);

      if (silent) {
        return;
      }

      setDialogInfo(dialogData);
      openDialog();
    } else {
      setDialogInfo(dialogData);
      openDialog();
    }
  }, []);

  useEffect(() => {
    startContractInfoPolling(
      (data) => {
        if (data.data.status === ORDER_STATUSES.WAITING_CONFIRMATION_CALL && AUTO_CALL_IS_ENABLED) {
          checkCallStatus();
          return false;
        }

        return onGetContractStatus(data);
      },
      onErrorPooling,
      POLLING_TIMEOUT,
    );

    return () => stopPolling();
  }, []);

  const handleDialogAgree = useCallback(() => {
    if (!dialogInfo) {
      return;
    }

    const { dialogType } = dialogInfo;

    switch (dialogType) {
    case CALLBACK_DIALOG_TYPES.SUCCESS: {
      closeDialog();
      break;
    }
    case CALLBACK_DIALOG_TYPES.FAILURE: {
      requestNewCall();
    }
    }
  }, [dialogInfo, closeDialog, requestNewCall]);

  return (
    <>
      <Box maxWidth="456px" margin="auto">
        <StepperArabic steps={steps} activeStep={0} />
        <Grid container>
          <Grid item margin="auto" mt="40px">
            <CallManager />
          </Grid>
          <Grid item textAlign="center" mt="34px">
            <Typography variant="h3" fontWeight="bold" mb="16px">
              {t("checkout.title.waitPhoneCall")}
            </Typography>
            <Typography variant="p1">{t("checkout.content.phoneConfirmation")}</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          display="flex"
          justifyContent="center"
          marginTop="40px"
          paddingBottom={isMobile ? "35px" : 0}
          maxWidth="100%"
        >
          <Button
            disabled={!isExpired}
            sx={{
              fontSize: { xs: "1.6rem" },
              padding: { xs: 0 },
              minWidth: { sm: "240px", xs: "150px" },
            }}
            onClick={() => requestNewCall(false)}
          >
            {`${t("buttons.callBack") as string} ${isExpired ? "" : countdown}`}
          </Button>
        </Grid>
      </Box>
      {dialogInfo?.dialog && (
        <Dialog
          open={isDialogOpen}
          dialog={dialogInfo.dialog}
          onAgree={handleDialogAgree}
          onClose={dialogInfo.dialogType == CALLBACK_DIALOG_TYPES.FAILURE ? closeDialog : noop}
        />
      )}
    </>
  );
};
