import { COMMON_REQUEST_DIALOGS, IDialogInfo } from "@appTypes/dialogs";
import { EMPLOYMENT_SECTORS } from "@constants/employmentsSectors";

import { EMPLOYMENT_DETAILS_DIALOGS } from "./EmploymentDetails/EmploymentDetails.types";

export interface IEmploymentDetailsRequestParams {
  accommodation?: string;
  dependents?: number;
  monthInstalments?: number;
  employmentSector: EMPLOYMENT_SECTORS;
  monthSalary?: number;
  financialDetails: IEmploymentFinancialDetail;
}

export interface IEmploymentFinancialDetail {
  responsiblePerson?: string;
  isResponsible?: boolean;
  isBreadwinner?: boolean;
  rdef?: boolean;
  homeOwner?: boolean;
  expenses: IEmploymentExpense[];
  dependents: IEmploymentExpense[];
}

export interface IEmploymentExpense {
  code: string;
  value: number;
}

export interface IEmploymentSectorResponse {
  result: boolean;
}

export interface IEmploymentDetailsResult extends IDialogInfo<COMMON_REQUEST_DIALOGS | EMPLOYMENT_DETAILS_DIALOGS> {
  isSuccess: boolean;
}

export const EmploymentDetailsFieldCode = {
  MONTHLY_RENT: "MONTHLY_RENT",
  HOUSEHOLD_WAGE: "HOUSEHOLD_WAGE",
  UTILITY_EXPENSE: "UTILITY_EXPENSE",
  INSURANCE_PREMIUM: "INSURANCE_PREMIUM",
  TRANSPORT_EXPENSE: "TRANSPORT_EXPENSE",
  HELTHCARE_EXPENSE: "HELTHCARE_EXPENSE",
  COMMUNICATION_EXPENSE: "COMMUNICATION_EXPENSE",
  FOOD_AND_LIVING_EXPENSE: "FOOD_AND_LIVING_EXPENSE",
  EXPAT_DEPENDENT_EXPENSE: "EXPAT_DEPENDENT_EXPENSE",
  EDUCATION_EXPENSE: "EDUCATION_EXPENSE",
  MONTHLY_REMITTANCE: "MONTHLY_REMITTANCE",
  OTHER_EXPENSE: "OTHER_EXPENSE",
  PUBLIC_SCHOOL: "PUBLIC_SCHOOL",
  PRIVATE_SCHOOL: "PRIVATE_SCHOOL",
  HOUSEHOLD_HELP: "HOUSEHOLD_HELP",
};
