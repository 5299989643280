import { Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import { Button, Typography } from "@ui-kit";
import { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { IDialogContents } from "@appTypes/dialogs";
import { BasicDialog } from "@components/Dialogs/BasicDialog";
import { TextWithEmail } from "@components/TextWithEmail";
import { SUPPORT_EMAIL } from "@constants/common";
import * as httpCodes from "@constants/httpStatuses";
import { STORAGE_DEFAULT_CONTRACT } from "@constants/sessionStorageDefaults";
import { CONTRACT_DATA_KEY, MERCHANT_DATA_KEY } from "@constants/sessionStorageKeys";
import { siteMap } from "@constants/siteMap";
import {
  MODAL_TYPES,
  offerDetailsModalContent,
} from "@pages/NewCreditLimitCalculation/CreditLimitCalculation.constants";
import { useCustomerDecision } from "@pages/NewCreditLimitCalculation/hooks/useCustomerDecision";
import { IRegularBNPLOffer } from "@pages/NewCreditLimitCalculation/hooks/useUserLink";
import { menuService } from "@store/menu";
import { useDialog } from "@utils/hooks/useDialog";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { getContractStatusRequest } from "@utils/network/contractInfo/getContractStatus";
import { logoutWithRedirect } from "@utils/services/Authentication/logoutWithRedirect";

import { AmountTxt, RequestAmountTxt, MainBox, BoxRow, CenterContainer } from "./styles";

type LocationProps = {
  state: {
    offerObject: IRegularBNPLOffer;
    reqAmount: number;
  };
};
interface IButtonsConfig {
  onAgree?(): void;
  onCancel?(): void;
}

export const OfferDetails: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation() as unknown as LocationProps;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [merchantData] = useSessionStorageState(MERCHANT_DATA_KEY, {
    checkoutToken: "",
    merchantUrl: "",
    merchantSuccessUrl: "",
    merchantFailureUrl: "",
    merchantCancelUrl: "",
  });
  const [sendDecisionRequest] = useCustomerDecision(merchantData.checkoutToken);
  const [contractData, setContractData] = useSessionStorageState(CONTRACT_DATA_KEY, STORAGE_DEFAULT_CONTRACT);

  // Dialog functions
  const [isDialogOpen, closeDialog, openDialog] = useDialog();
  const [modalType, setModalType] = useState<keyof typeof MODAL_TYPES>(MODAL_TYPES.OFFER_EXPIRED);
  const [modalContent, setModalContent] = useState<IDialogContents>(offerDetailsModalContent.OFFER_EXPIRED);

  useEffect(() => {
    menuService.setMenuData({ leftSide: "", rightSide: "", needIcon: false });
    console.log(location.state.offerObject);
  }, []);

  const handleContinueClick = async () => {
    try {
      const { status } = await sendDecisionRequest("CONFIRM", {
        offerType: location.state.offerObject.offerType.toUpperCase(),
        ...{},
      });
      // TODO: RETRY -
      const {
        data: { promissoryNoteState },
      } = await getContractStatusRequest();

      await setContractData({ ...contractData, ePromissoryNoteState: promissoryNoteState });

      if (status === httpCodes.OK) {
        history.push(siteMap.ConfirmationPage.path);
      } else {
        history.push(siteMap.Error404Page.path);
      }
    } catch (e) {
      openUnexpectedErrorDialog();
    }
  };

  const handleCancelClick = () => {
    setModalType(MODAL_TYPES.CONFIRM_CANCEL_PROCESS);
    setModalContent(offerDetailsModalContent[MODAL_TYPES.CONFIRM_CANCEL_PROCESS]);
    openDialog();
  };

  const handleCancelOffer = async () => {
    try {
      await sendDecisionRequest("DECLINE");
      await logoutWithRedirect(merchantData.merchantCancelUrl);
    } catch (e) {
      console.log("Decline offer error: ", e);
    }
  };

  const handleGoToMerchantCancel = async () => {
    await logoutWithRedirect(merchantData.merchantCancelUrl);
  };
  const handleGoToMerchantFailure = async () => {
    await logoutWithRedirect(merchantData.merchantFailureUrl);
  };

  const modalButtonsConfig = useMemo(() => {
    const modalHandlers: Record<keyof typeof MODAL_TYPES, IButtonsConfig> = {
      CONFIRM_CANCEL_PROCESS: {
        onCancel: closeDialog,
        onAgree: handleCancelOffer,
      },
      OFFER_EXPIRED: {
        onAgree: handleGoToMerchantCancel,
      },
      CAN_NOT_PROVIDE_OFFER: {
        onAgree: handleGoToMerchantFailure,
      },
    };

    return { ...modalHandlers[modalType] } || {};
  }, [modalType]);

  const openUnexpectedErrorDialog = () => {
    setModalType(MODAL_TYPES.CAN_NOT_PROVIDE_OFFER);
    setModalContent({
      ...offerDetailsModalContent.CAN_NOT_PROVIDE_OFFER,
      description: (
        <TextWithEmail
          textKey={offerDetailsModalContent.CAN_NOT_PROVIDE_OFFER.description as string}
          email={SUPPORT_EMAIL}
        />
      ),
    });
    openDialog();
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent={"center"}>
            <RequestAmountTxt variant="h4">{t("offerDetails.totalAmount")}</RequestAmountTxt>
            <AmountTxt variant="h4">
              {t("currency.SAR")} {location.state.offerObject.loanAmount}
            </AmountTxt>
          </Stack>
        </Grid>
        <CenterContainer item xs={12} textAlign={"center"}>
          <Grid
            item
            sm={12}
            textAlign="start"
            paddingBottom={2}
            paddingTop={2}
          >
            <Typography variant="h5" fontWeight={500}>
              {t("offerDetails.description")}
            </Typography>
          </Grid>
          <MainBox>
            <BoxRow>
              <Typography>{t("offerDetails.requested_amount")}:</Typography>
              <Typography fontWeight={700}>
                {location.state.reqAmount} {t("currency.SAR")}
              </Typography>
            </BoxRow>
            <BoxRow>
              <Typography>{t("offerDetails.profit")}:</Typography>
              <Typography>
                {location.state.offerObject.profit} {t("currency.SAR")}
              </Typography>
            </BoxRow>
            <BoxRow>
              <Typography>{t("offerDetails.tenure")}:</Typography>
              <Typography>
                {location.state.offerObject.tenure} {t("units.month_other")}
              </Typography>
            </BoxRow>
            <BoxRow>
              <Typography>{t("offerDetails.monthly_payment")}:</Typography>
              <Typography>
                {location.state.offerObject.installments} {t("currency.SAR")}
              </Typography>
            </BoxRow>
            <BoxRow borderBottom={"0px !important"} marginBottom={"0px !important"} paddingBottom={"0px !important"}>
              <Typography>{t("offerDetails.interest_rate")}:</Typography>
              <Typography>{location.state.offerObject.interestRate}%</Typography>
            </BoxRow>
          </MainBox>
        </CenterContainer>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent={{ sm: "space-around" }}
        >
          <Button
            tertiary
            sx={{ width: { xs: "157px", sm: "240px" } }}
            onClick={handleCancelClick}
            color={!isMobile ? "secondary" : "inherit"}
          >
            {t("buttons.reject") as string}
          </Button>
          <Button onClick={handleContinueClick} sx={{ width: { xs: "157px", sm: "240px" } }}>
            {t("buttons.accept") as string}
          </Button>
        </Grid>
      </Grid>
      <BasicDialog
        open={isDialogOpen}
        icon={modalContent.icon}
        title={t(modalContent.title)}
        onAgree={modalButtonsConfig.onAgree}
        onCancel={modalButtonsConfig.onCancel}
        description={
          <Typography variant="p3" textAlign="center" component="p">
            {typeof modalContent.description === "string" ? t(modalContent.description) : modalContent.description}
          </Typography>
        }
        agreeButtonText={t(modalContent.agreeText || "")}
        cancelButtonText={t(modalContent.cancelText || "")}
      />
    </>
  );
};
