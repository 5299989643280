import { FormHelperText, useTheme } from "@mui/material";
import { COLORS } from "@ui-kit";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { COUNTDOWN_TIMES } from "@utils/services/Timer/Timer.service";

interface ITimerProps {
  isLoginBlocked: boolean;
  setIsLoginBlocked: Dispatch<SetStateAction<boolean>>;
  blockingTime: string;
}

export const Timer: React.FC<ITimerProps> = ({ isLoginBlocked, setIsLoginBlocked, blockingTime }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [counter, setCounter] = useState(
    new Date().getTime() - new Date(blockingTime).getTime() + COUNTDOWN_TIMES["5min"],
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      if (counter > 0) {
        setCounter(counter - 1000);
      } else {
        setIsLoginBlocked(false);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [counter]);

  return isLoginBlocked ? (
    <FormHelperText
      sx={{
        color: COLORS.DARK_NEGATIVE,
        fontWeight: theme.direction === "rtl" ? 600 : "bold",
      }}
    >
      {t("errors.LOGIN_ATTEMPTS_EXCEEDED")}
    </FormHelperText>
  ) : null;
};
