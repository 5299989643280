export const SUPPORT_EMAIL = "support@jak.finance";

export enum OSTypes {
  iOS = "iOS",
  Android = "Android",
  PC = "PC",
}

export const LINK_TO_APP_ANDROID = "https://play.google.com/store/apps/details?id=com.quarafinance.q99pay";
export const LINK_TO_APP_APPLE = "itms-apps://itunes.apple.com/app/id1597089385";
export const LINK_TO_APP_APPLE_DESKTOP = "https://apps.apple.com/sa/app/%D9%83%D9%88%D8%A7%D8%B1%D8%A7-99/id1597089385";
