import { Box } from "@mui/material";
import { COLORS, Typography } from "@ui-kit";
import { FC, useMemo } from "react";

import { CountdownTimer } from "@components/CountdownTimer";
import { COUNTDOWN_TIMES, getTimeRemaining } from "@utils/services/Timer/Timer.service";

export const LoanTimer: FC<{ loanCreated: string; label: string }> = ({ loanCreated, label }) => {
  const date = useMemo(() => {
    return loanCreated ? new Date(loanCreated) : new Date();
  }, [loanCreated]);
  const timerData = getTimeRemaining(date, COUNTDOWN_TIMES["36h"], false);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="p2" color={COLORS.GREY_32}>
        {label}
      </Typography>
      {timerData && (
        <CountdownTimer
          typographyVariant="h5"
          seconds={timerData?.seconds}
          minutes={timerData?.minutes}
          hours={timerData?.hours}
        />
      )}
    </Box>
  );
};
