import { AxiosResponse } from "axios";

import { useCustomerLanguage } from "@utils/hooks/useCheckCustomerLanguage/useCustomerLanguage";
import { network } from "@utils/network/network";

export interface IOfferResponse {
  message: string;
}

type decisionType = "CONFIRM" | "DECLINE";

interface IOfferData {
  offerType: string;
}

type useRequestType = [
  sendOfferDecision: (decision: decisionType, offerData?: IOfferData) => Promise<AxiosResponse<IOfferResponse>>,
];

export const useCustomerDecision = (checkoutToken: string): useRequestType => {
  const { getCustomerLanguage } = useCustomerLanguage();

  const sendOfferDecision = (
    decision: decisionType,
    offerData?: IOfferData,
  ): Promise<AxiosResponse<IOfferResponse>> => {
    const chosenOffer = offerData
      ? {
        ...offerData,
      }
      : {};

    const lang = getCustomerLanguage();

    return network.request<IOfferResponse>("order", "customer/checkout/offer", {
      method: "POST",
      params: {
        checkoutToken,
        decision,
        lang,
        ...chosenOffer,
      },
    });
  };

  return [sendOfferDecision];
};
