import { Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import { Typography, Button } from "@ui-kit";
import React from "react";
import { Link } from "react-router-dom";
import { noop } from "rxjs";

const LogInButton = styled(Button)(({ theme }) => ({
  fontWeight: theme.direction === "ltr" ? 700 : 600,
  fontSize: theme.direction === "ltr" ? "1.4rem" : "1.6rem",
  backgroundColor: theme.palette.secondary.navy,
}));

const Header = styled(Grid)(({ theme }) => ({
  justifyContent: "space-between",
  marginBottom: 0,
  [theme.breakpoints.up("sm")]: {
    marginBottom: "40px",
  },
}));

const HeaderText = styled(Grid)`
  display: flex;
  align-items: center;
`;

const HeaderTextPart = styled(Typography)(({ theme }) => ({
  fontWeight: theme.direction === "ltr" ? "bold" : 600,
  fontSize: `${theme.direction === "ltr" ? "2.4rem" : "2.8rem"}!important`,
  marginInlineEnd: 8,
}));

interface IStartPageHeader {
  linkPathname: string;
  buttonText: string;
  rightSideHeaderText: string;
  leftSideHeaderText: string;
  onLoginClick?: () => void;
}

export const StartPageHeader: React.FC<IStartPageHeader> = ({
  buttonText,
  rightSideHeaderText,
  leftSideHeaderText,
  linkPathname,
  onLoginClick = noop,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Header container>
      <HeaderText item alignContent="center">
        <HeaderTextPart variant={isMobile ? "p1" : "h5"}>{leftSideHeaderText}</HeaderTextPart>

        <HeaderTextPart variant={isMobile ? "p1" : "h5"}> {rightSideHeaderText}</HeaderTextPart>
      </HeaderText>
      <Grid item>
        <LogInButton
          component={Link}
          size="small"
          sx={{ padding: "8px 16px" }}
          to={linkPathname}
          onClick={onLoginClick}
        >
          {buttonText}
        </LogInButton>
      </Grid>
    </Header>
  );
};
