import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { BaseInput } from "@ui-kit";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { COMMON_REQUEST_DIALOGS, IDialogWithHandler } from "@appTypes/dialogs";
import { StartPageHeader } from "@components/StartPageHeader/StartPageHeader";
import { TextWithLink } from "@components/TextWithLink/TextWithLink";
import { emptyUserData } from "@constants/authorization";
import * as httpCodes from "@constants/httpStatuses";
import { dialogContents } from "@constants/popups";
import { DEFAULT_USER_REGISTRATION_INFO } from "@constants/sessionStorageDefaults";
import { REGISTRATION_KEY } from "@constants/sessionStorageKeys";
import { siteMap } from "@constants/siteMap";
import { Timer } from "@pages/Login/components/Timer";
import { loginInfoValidators } from "@pages/Login/login.validators";
import { authorizationStore } from "@store/authorization";
import { menuService } from "@store/menu";
import { useDialog } from "@utils/hooks/useDialog";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { useGenerateOTP } from "@utils/network/useGenerateOTP";
import { validateFormFields } from "@utils/services/Validation";
import { hasEmptyFields } from "@utils/services/Validation/validators";

import { StyledForgotPasswordLink, StyledGrid, StyledLoginButton, StyledPasswordInput } from "./Login.styles";
import { UserLoginDialogs } from "./components/UserLoginDialogs";
import { useLogin } from "./hooks/useLogin";
import {
  DEFAULT_FORM_DATA,
  FORM_FIELDS,
  DEFAULT_FORM_ERRORS,
  NATIONAL_ID_IS_MANDATORY_ERROR,
  WRONG_PASSWORD_ERROR,
} from "./login.constants";
import { FormFields, FormErrors, loginDialogs, LOGIN_DIALOGS, IDialogOptions } from "./login.types";

export const Login: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isDialogOpen, closeDialog, openDialog] = useDialog();
  const [dialogInfo, setDialogInfo] = useState<IDialogWithHandler<loginDialogs, IDialogOptions>>();
  const [isLoginBlocked, setIsLoginBlocked] = useState(false);
  const [blockingTime, setBlockingTime] = useState<string | undefined>();
  const [formFields, setFormData] = useState<FormFields>(DEFAULT_FORM_DATA);
  const [errors, setFormErrors] = useState<FormErrors>(DEFAULT_FORM_ERRORS);
  const [userLoginInfo, setUserDataToStorage] = useSessionStorageState(
    REGISTRATION_KEY,
    DEFAULT_USER_REGISTRATION_INFO,
  );
  const isLoginDisabled = hasEmptyFields(formFields);

  const { loginUser } = useLogin();
  const [getNewOTPCode] = useGenerateOTP();

  useEffect(() => {
    authorizationStore.setAuthorization(emptyUserData);

    menuService.setMenuData({
      leftSide: "pageHeader.login.login",
      rightSide: "pageHeader.login.account",
      needIcon: true,
    });
  }, []);

  const generateOTP = async () => {
    const { status, data } = await getNewOTPCode();

    if (status !== httpCodes.OK) {
      throw "Generating OTP error";
    }
    if (status === httpCodes.OK) {
      return data.otpGeneratedAt;
    }
  };

  const handleNumberInputChange =
    (formKey: string, maxLength: number) => (event: React.ChangeEvent<{ value: string }>) => {
      if (event.target.value.length <= maxLength) {
        setFormData((prevState) => ({ ...prevState, [formKey]: event.target.value }));
      }
      event.target.value.length === 0
        ? setFormErrors({ ...errors, NATIONAL_ID: [NATIONAL_ID_IS_MANDATORY_ERROR] })
        : setFormErrors({ ...errors, NATIONAL_ID: [] });
    };

  const handlePasswordInputChange = (event: React.ChangeEvent<{ value: string }>) => {
    setFormData((prevState) => ({ ...prevState, PASSWORD: event.target.value }));
  };

  const generateOPTReq = async () => {
    const otpGeneratedAt = await generateOTP();
    otpGeneratedAt &&
      setUserDataToStorage({
        form: {
          ...userLoginInfo.form,
          NATIONAL_ID: formFields.NATIONAL_ID,
        },
      });
    history.push(siteMap.LoginOTPPage.path);
  };

  const handleLoginClick = async () => {
    const { isFormValid, formErrors } = validateFormFields(formFields, loginInfoValidators);

    if (isFormValid) {
      setFormErrors(DEFAULT_FORM_ERRORS);
      const { isSuccess, dialogType, dialog, dialogOptions, token } = await loginUser({
        nationalId: formFields.NATIONAL_ID,
        password: formFields.PASSWORD,
      });

      if (isSuccess) {
        token && authorizationStore.setAuthorization(token);

        try {
          await generateOPTReq();
        } catch (e) {
          setDialogInfo({
            dialogType: COMMON_REQUEST_DIALOGS.SOMETHING_WENT_WRONG,
            dialog: dialogContents.somethingWentWrong,
            lastAction: generateOPTReq,
          });
          openDialog();
        }
      } else {
        if (dialogType === LOGIN_DIALOGS.USER_WRONG_CREEDS) {
          setFormErrors({ ...errors, PASSWORD: [WRONG_PASSWORD_ERROR] });
        } else if (dialogType === LOGIN_DIALOGS.USER_LOGIN_ATTEMPTS_BLOCKED) {
          setIsLoginBlocked(true);
          setBlockingTime(dialogOptions?.blockTimer);
        } else {
          setDialogInfo({ dialog, dialogType, dialogOptions });
          openDialog();
        }
      }
    } else {
      setFormErrors(formErrors as FormErrors);
    }
  };

  const handleCloseDialog = useCallback(() => {
    setDialogInfo({});
    closeDialog();
  }, [closeDialog]);

  return (
    <>
      <Box>
        {!isMobile && (
          <StartPageHeader
            buttonText={t("buttons.createAccount")}
            leftSideHeaderText={t("pageHeader.login.login")}
            rightSideHeaderText={t("pageHeader.login.account")}
            linkPathname={siteMap.CreateAccountPage.path}
          />
        )}
        <Grid container flexDirection="column" rowSpacing={3}>
          <Grid item container columnSpacing={5}>
            <Grid item md={6} sm={12}>
              <BaseInput
                onChange={handleNumberInputChange(FORM_FIELDS.NATIONAL_ID, 10)}
                value={formFields[FORM_FIELDS.NATIONAL_ID]}
                errorText={
                  errors[FORM_FIELDS.NATIONAL_ID].length ? t(`errors.${errors[FORM_FIELDS.NATIONAL_ID][0]}`) : ""
                }
                type="text"
                fullWidth
                label={t("labels.nationalIdOrIQAMA")}
                labelId="national-id"
                placeholder={t("placeholders.nationalIdOrIQAMA")}
                allowNumbersOnly={true}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <StyledPasswordInput
                onChange={handlePasswordInputChange}
                errorText={errors[FORM_FIELDS.PASSWORD].length ? t(`errors.${errors[FORM_FIELDS.PASSWORD][0]}`) : ""}
                value={formFields[FORM_FIELDS.PASSWORD]}
                fullWidth
                label={t("labels.password")}
                placeholder={t("placeholders.password")}
                labelId="password"
                isEmpty={!formFields[FORM_FIELDS.PASSWORD]}
              />
            </Grid>
            {isLoginBlocked && blockingTime && (
              <Grid item md={6} sm={12}>
                {" "}
                <Timer
                  setIsLoginBlocked={setIsLoginBlocked}
                  isLoginBlocked={isLoginBlocked}
                  blockingTime={blockingTime}
                />
              </Grid>
            )}
          </Grid>
          <StyledGrid
            item
            container
            xs={12}
            justifyContent={isMobile ? "flex-start" : "space-between"}
            alignItems="center"
          >
            <StyledForgotPasswordLink to={siteMap.ForgotPasswordPage.path}>
              {t("additionalInfo.forgotPassword")}
            </StyledForgotPasswordLink>
            <StyledLoginButton
              onClick={handleLoginClick}
              disabled={isLoginDisabled || isLoginBlocked}
              variant="contained"
            >
              {t("buttons.logIn") as string}
            </StyledLoginButton>
            {isMobile ? (
              <TextWithLink
                text={t("pageContent.dontHaveAccount")}
                linkPath={siteMap.CreateAccountPage.path}
                linkText={t("buttons.createAccount")}
              />
            ) : null}
          </StyledGrid>
        </Grid>
      </Box>
      {isDialogOpen && (
        <UserLoginDialogs
          {...dialogInfo}
          closeDialog={handleCloseDialog}
          isDialogOpen={isDialogOpen}
          openDialog={openDialog}
        />
      )}
    </>
  );
};
