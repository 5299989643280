import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, styled } from "@mui/material";
import React, { ChangeEvent, FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
export type TTextValue = {
  text: string;
  value: string;
};

export type TStyledRadioButtonGroupProps = {
  groupId: string;
  groupTitle: string;
  // eslint-disable-next-line
  value: any;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  textValues: TTextValue[];
};

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  border: "1px solid #DFE2E6",
  paddingTop: "5px",
  paddingBottom: "5px",
  paddingLeft: theme.direction == "rtl" ? "20px" : "10x",
  paddingRight: theme.direction == "ltr" ? "20px" : "10px",
  borderRadius: "10px",
  marginTop: "1px",
  marginLeft: theme.direction == "rtl" ? "10px" : "0px",
  marginRight: theme.direction == "ltr" ? "10px" : "0px",
}));

const StyledRadio = styled(Radio)(() => ({
  "&, &.Mui-checked": {
    color: "#425CC7",
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  fontSize: "1.2rem",
  lineHeight: "1.4375em",
  fontWeight: "bold",
  color: "#6B727C",
  "&, &.Mui-focused": {
    color: "#6B727C",
  },
}));

const StyledRadioButtonGroup: FC<TStyledRadioButtonGroupProps> = ({
  groupId,
  groupTitle,
  value,
  onChange,
  textValues,
}) => {
  const { i18n } = useTranslation();
  const radioButtons = useMemo(() => {
    const buttons = textValues.map((item) => {
      return (
        <StyledFormControlLabel
          key={`${groupId}_${item.value}`}
          value={item.value}
          control={<StyledRadio />}
          label={item.text}
        />
      );
    });
    return buttons;
  }, [i18n.language]);
  return (
    <FormControl>
      <StyledFormLabel id={groupId}>{groupTitle}</StyledFormLabel>
      <RadioGroup
        value={value}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={onChange}
      >
        {radioButtons}
      </RadioGroup>
    </FormControl>
  );
};

export default StyledRadioButtonGroup;
