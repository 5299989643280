import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Grid,
  Link,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { BaseInput, Button, CurrencyInput, Dropdown, QuantityInput, Typography, ArrowDropDownIcon } from "@ui-kit";
import React, { useCallback, useEffect, useReducer, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { CUSTOMER_STATUSES } from "@appTypes/customerStatuses";
import { IDialogWithHandler } from "@appTypes/dialogs";
import StyledRadioButtonGroup, { TTextValue } from "@components/RadioButtonsGroup/StyledRadioButtonGroup";
import { EMPLOYMENT_SECTORS, EMPLOYMENT_SECTORS_ACTIONS } from "@constants/employmentsSectors";
import { MERCHANT_DATA_KEY, USER_INFO_KEY } from "@constants/sessionStorageKeys";
import { siteMap } from "@constants/siteMap";
import { ACCOMMODATION_TYPES } from "@modules/EmploymentSector/EmploymentDetails/EmploymentDetails.constants";
import { useEmploymentDetails } from "@modules/EmploymentSector/EmploymentDetails/hooks/useEmploymentDetails";
import { menuService } from "@store/menu";
import { useSRSFlow } from "@utils/hooks/useCheckSRS/useSRSFlow";
import { useDialog } from "@utils/hooks/useDialog";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { convertArabicToDigit, convertDigitsToArabic } from "@utils/services/Text/Text.service";

import { EmploymentDetailsFieldCode, IEmploymentDetailsRequestParams } from "../EmploymentSector.types";
import { EmploymentDialogs } from "../components/EmploymentDialogs";
import { employmentDialogTypes, EMPLOYMENT_DETAILS_DIALOGS, TEmploymentDetails } from "./EmploymentDetails.types";

const StyledDropdown = styled(Dropdown)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    width: "376px",
  },
}));

const StyledQuantityInput = styled(QuantityInput)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    width: "247px",
  },
}));

const StyledContinueButton = styled(Button)(({ theme }) => ({
  fontWeight: theme.direction === "ltr" ? "bold" : 600,
  width: "100%",
  maxWidth: "376px",
  [theme.breakpoints.up("sm")]: {
    width: "270px",
  },
}));

const StyledAccordion = styled(Accordion)(() => ({
  marginBottom: "15px",
  borderWidth: "0px",
  borderRadius: "10px",
  "&::before": {
    opacity: 0,
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  padding: "10px",
  paddingLeft: "30px",
}));

const expensionIcon = <ArrowDropDownIcon />;

const employmentDetails: TEmploymentDetails = {
  employmentSector: EMPLOYMENT_SECTORS.PRIVATE,
  monthlyIncome: 0,
  monthlyRent: 0,
  householdWage: 0,
  utilityExpense: 0,
  insurancePremium: 0,
  transportExpense: 0,
  healthcareExpense: 0,
  communicationExpense: 0,
  foodAndLivingExpense: 0,
  expatDependentGovtFees: 0,
  educationSuppliesExpense: 0,
  monthlyRemittance: 0,
  otherMonthlyExpense: 0,
  allDependants: 0,
  dependantsPrivateSchool: 0,
  dependantsPublicSchool: 0,
  householdHelp: 0,
  accomodationType: "",
  isBreadWinner: "NO",
  isHomeOwner: "NO",
  isRDEF: "NO",
  isResponsibleForPayment: "NO",
  responsiblePersonName: "",
};

// eslint-disable-next-line
const employmentReducer = (state: TEmploymentDetails, action: any): TEmploymentDetails => {
  switch (action.type) {
  case EMPLOYMENT_SECTORS_ACTIONS.SECTOR:
    return { ...state, employmentSector: action.value };
  case EMPLOYMENT_SECTORS_ACTIONS.MONTHLY_INCOME:
    return { ...state, monthlyIncome: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.MONTHLY_RENT:
    return { ...state, monthlyRent: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.HOUSEHOLD_WAGE:
    return { ...state, householdWage: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.UTILITY_EXPENSE:
    return { ...state, utilityExpense: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.INSURANCE_PREMIUM:
    return { ...state, insurancePremium: parseInt(action.value) };

  case EMPLOYMENT_SECTORS_ACTIONS.TRANSPORT_EXPENSE:
    return { ...state, transportExpense: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.HEALTHCARE_EXPENSE:
    return { ...state, healthcareExpense: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.COMMUNICATION_EXPENSE:
    return { ...state, communicationExpense: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.FOOD_LIVING_EXPENSE:
    return { ...state, foodAndLivingExpense: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.EXPAT_DEPENDENT_GOVT_FEES:
    return { ...state, expatDependentGovtFees: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.EDUCATION_SUPPLIES_EXPENSE:
    return { ...state, educationSuppliesExpense: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.MONTHLY_REMITTANCE:
    return { ...state, monthlyRemittance: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.OTHER_MONTHLY_EXPENSE:
    return { ...state, otherMonthlyExpense: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.NUMBER_OF_DEPENDANTS_PUBLIC_SCHOOL:
    return { ...state, dependantsPublicSchool: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.NUMBER_OF_DEPENDANTS_PRIVATE_SCHOOL:
    return { ...state, dependantsPrivateSchool: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.NUMBER_OF_HOUSEHOLD_HELP:
    return { ...state, householdHelp: parseInt(action.value) };
  case EMPLOYMENT_SECTORS_ACTIONS.NUMBER_OF_ALL_DEPENDANTS:
    return { ...state, allDependants: parseInt(action.value) };

  case EMPLOYMENT_SECTORS_ACTIONS.RESPONSIBLE_FOR_PAYMENT:
    if (action.value == "YES") return { ...state, isResponsibleForPayment: action.value, responsiblePersonName: "" };
    else
      return { ...state, isResponsibleForPayment: action.value, responsiblePersonName: state.responsiblePersonName };
  case EMPLOYMENT_SECTORS_ACTIONS.PERSON_RESPONSIBLE:
    return { ...state, responsiblePersonName: action.value };
  case EMPLOYMENT_SECTORS_ACTIONS.SOLE_BREADWINNER:
    return { ...state, isBreadWinner: action.value };
  case EMPLOYMENT_SECTORS_ACTIONS.RDEF:
    return { ...state, isRDEF: action.value };
  case EMPLOYMENT_SECTORS_ACTIONS.ACCOMODATION_TYPE:
    return { ...state, accomodationType: action.value };
  case EMPLOYMENT_SECTORS_ACTIONS.HOME_OWNER:
    return { ...state, isHomeOwner: action.value };
  default:
    return state;
  }
};

export const EmploymentDetails: React.FC = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const history = useHistory();

  const [isDialogOpen, closeDialog, openDialog] = useDialog();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [userLoginInfo] = useSessionStorageState(USER_INFO_KEY, { status: "" });
  const [merchantToken] = useSessionStorageState<{ checkoutToken: string; merchantUrl: string }>(MERCHANT_DATA_KEY, {
    checkoutToken: "",
    merchantUrl: "",
  });
  const [dialogInfo, setDialogInfo] = useState<IDialogWithHandler<employmentDialogTypes, void>>();

  const { setEmploymentDetails } = useEmploymentDetails();
  const { runSrsFlow } = useSRSFlow();

  const yesNoValues = useMemo<TTextValue[]>(() => {
    return [
      { text: t("buttons.yes"), value: "YES" },
      { text: t("buttons.no"), value: "NO" },
    ];
  }, [i18n.language]);

  const parseNumber = useCallback(
    (value: string) => {
      const isEngLang = i18n.language === "en";

      if (isEngLang) {
        if (isNaN(+value)) {
          return "0";
        }
      } else {
        if (isNaN(+convertArabicToDigit(value))) {
          return "0";
        }
      }
      return isEngLang ? value || "0" : convertDigitsToArabic(value || "0");
    },
    [i18n.language],
  );

  const [employmentData, dispatch] = useReducer(employmentReducer, employmentDetails);
  const [consent, setConsent] = useState(false);

  const handleContinueClick = async () => {
    const setEmploymentRequestData = async (token: string, employmentRequestData: IEmploymentDetailsRequestParams) => {
      const { isSuccess, ...dialogData } = await setEmploymentDetails(token, employmentRequestData);
      if (isSuccess) {
        if (userLoginInfo.status !== CUSTOMER_STATUSES.ACTIVE) {
          const srsResult = await runSrsFlow(siteMap.NewCreditLimitCalculationPage.path);

          if (srsResult) {
            openDialog();
            setDialogInfo(srsResult);
          } else {
            history.push(siteMap.NewCreditLimitCalculationPage.path);
          }
        } else {
          history.push(siteMap.NewCreditLimitCalculationPage.path);
        }
      } else {
        setDialogInfo({ ...dialogData, lastAction: setEmploymentRequestData, parameters: [token, employmentData] });
        openDialog();
      }
    };
    setEmploymentRequestData(merchantToken.checkoutToken, {
      employmentSector: employmentData.employmentSector,
      accommodation: employmentData.accomodationType == "labels.rental" ? "Rental" : "Own house",
      dependents: employmentData.allDependants,
      monthInstalments: 0,
      monthSalary: employmentData.monthlyIncome,
      financialDetails: {
        homeOwner: employmentData.isHomeOwner == "YES",
        isBreadwinner: employmentData.isBreadWinner == "YES",
        isResponsible: employmentData.isResponsibleForPayment == "YES",
        responsiblePerson: employmentData.responsiblePersonName,
        rdef: employmentData.isRDEF == "YES",
        expenses: [
          { code: EmploymentDetailsFieldCode.MONTHLY_RENT, value: employmentData.monthlyRent },
          { code: EmploymentDetailsFieldCode.HOUSEHOLD_WAGE, value: employmentData.householdWage },
          { code: EmploymentDetailsFieldCode.UTILITY_EXPENSE, value: employmentData.utilityExpense },
          { code: EmploymentDetailsFieldCode.INSURANCE_PREMIUM, value: employmentData.insurancePremium },
          { code: EmploymentDetailsFieldCode.TRANSPORT_EXPENSE, value: employmentData.transportExpense },
          { code: EmploymentDetailsFieldCode.HELTHCARE_EXPENSE, value: employmentData.healthcareExpense },
          { code: EmploymentDetailsFieldCode.COMMUNICATION_EXPENSE, value: employmentData.communicationExpense },
          { code: EmploymentDetailsFieldCode.FOOD_AND_LIVING_EXPENSE, value: employmentData.foodAndLivingExpense },
          { code: EmploymentDetailsFieldCode.EXPAT_DEPENDENT_EXPENSE, value: employmentData.expatDependentGovtFees },
          { code: EmploymentDetailsFieldCode.EDUCATION_EXPENSE, value: employmentData.educationSuppliesExpense },
          { code: EmploymentDetailsFieldCode.MONTHLY_REMITTANCE, value: employmentData.monthlyRemittance },
          { code: EmploymentDetailsFieldCode.OTHER_EXPENSE, value: employmentData.otherMonthlyExpense },
        ],
        dependents: [
          { code: EmploymentDetailsFieldCode.PUBLIC_SCHOOL, value: employmentData.dependantsPublicSchool },
          { code: EmploymentDetailsFieldCode.PRIVATE_SCHOOL, value: employmentData.dependantsPrivateSchool },
          { code: EmploymentDetailsFieldCode.HOUSEHOLD_HELP, value: employmentData.householdHelp },
        ],
      },
    });
  };
  const handleSetConsent = () => {
    setConsent((value) => !value);
  };

  const handleOpenDialog = (dialogData: IDialogWithHandler<employmentDialogTypes, void>): void => {
    setDialogInfo(dialogData);
    openDialog();
  };
  const handleOpenConsentDialog = useCallback(() => {
    setDialogInfo({
      dialogType: EMPLOYMENT_DETAILS_DIALOGS.CONSENT,
      dialog: { title: "", description: "", agreeText: t("buttons.accept") },
    });
    openDialog();
  }, []);

  const translatedOptions = useMemo(() => {
    return Object.values(ACCOMMODATION_TYPES).map((item: { label: string; key: string }) => ({
      label: t(item.label),
      key: item.key,
    }));
  }, [i18n.language]);

  const translatedSectorOptions = useMemo(() => {
    return [
      { text: t("pageContent.privateSector"), value: "PRIVATE" },
      { text: t("pageContent.governmentSector"), value: "GOVERNMENT" },
    ];
  }, [i18n.language]);

  const handleCloseDialog = useCallback(() => {
    setDialogInfo({});
    closeDialog();
  }, [closeDialog]);

  useEffect(() => {
    menuService.setMenuData({ leftSide: "pageHeader.employmentDetails", rightSide: "", needIcon: false });
  }, []);

  return (
    <>
      {!isMobile && (
        <Typography
          variant="h5"
          fontWeight="bold"
          component="h1"
          marginBottom="58px"
        >
          {t("pageHeader.employmentDetails")}
        </Typography>
      )}
      <Grid container spacing={2}>
        <Grid item lg={6} xs={12}>
          <StyledRadioButtonGroup
            groupId="sector-button-group"
            groupTitle={t("buttons.selectSector")}
            value={employmentData.employmentSector}
            textValues={translatedSectorOptions}
            onChange={(e) => dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.SECTOR, value: e.target.value })}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <CurrencyInput
            currency={t("currency.SAR")}
            label={t("labels.monthlyIncome")}
            labelId="monthly-income"
            placeholder="0.00"
            value={employmentData.monthlyIncome}
            onChange={(e) =>
              dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.MONTHLY_INCOME, value: parseNumber(e.target.value) })
            }
          />
        </Grid>
      </Grid>

      <StyledAccordion>
        <StyledAccordionSummary expandIcon={expensionIcon}>
          <Typography fontWeight="bold">{t("labels.monthlyExpenses")}</Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <CurrencyInput
                currency={t("currency.SAR")}
                label={t("labels.monthlyRent")}
                labelId="monthly-rent"
                placeholder="0.00"
                value={employmentData.monthlyRent}
                onChange={(e) =>
                  dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.MONTHLY_RENT, value: parseNumber(e.target.value) })
                }
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <CurrencyInput
                currency={t("currency.SAR")}
                label={t("labels.householdWage")}
                labelId="household-wage"
                placeholder="0.00"
                value={employmentData.householdWage}
                onChange={(e) =>
                  dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.HOUSEHOLD_WAGE, value: parseNumber(e.target.value) })
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <CurrencyInput
                currency={t("currency.SAR")}
                label={t("labels.utilityExpense")}
                labelId="utility-expense"
                placeholder="0.00"
                value={employmentData.utilityExpense}
                onChange={(e) =>
                  dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.UTILITY_EXPENSE, value: parseNumber(e.target.value) })
                }
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <CurrencyInput
                currency={t("currency.SAR")}
                label={t("labels.insurancePremium")}
                labelId="insurance-premium"
                placeholder="0.00"
                value={employmentData.insurancePremium}
                onChange={(e) =>
                  dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.INSURANCE_PREMIUM, value: parseNumber(e.target.value) })
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <CurrencyInput
                currency={t("currency.SAR")}
                label={t("labels.transportExpenses")}
                labelId="transport-expenses"
                placeholder="0.00"
                value={employmentData.transportExpense}
                onChange={(e) =>
                  dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.TRANSPORT_EXPENSE, value: parseNumber(e.target.value) })
                }
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <CurrencyInput
                currency={t("currency.SAR")}
                label={t("labels.healthcareExpenses")}
                labelId="healthcare-expenses"
                placeholder="0.00"
                value={employmentData.healthcareExpense}
                onChange={(e) =>
                  dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.HEALTHCARE_EXPENSE, value: parseNumber(e.target.value) })
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <CurrencyInput
                currency={t("currency.SAR")}
                label={t("labels.communicationExpenses")}
                labelId="communication-expenses"
                placeholder="0.00"
                value={employmentData.communicationExpense}
                onChange={(e) =>
                  dispatch({
                    type: EMPLOYMENT_SECTORS_ACTIONS.COMMUNICATION_EXPENSE,
                    value: parseNumber(e.target.value),
                  })
                }
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <CurrencyInput
                currency={t("currency.SAR")}
                label={t("labels.foodAndLivingExpenses")}
                labelId="food-and-living-expenses"
                placeholder="0.00"
                value={employmentData.foodAndLivingExpense}
                onChange={(e) =>
                  dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.FOOD_LIVING_EXPENSE, value: parseNumber(e.target.value) })
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <CurrencyInput
                currency={t("currency.SAR")}
                label={t("labels.expatDependentGovFees")}
                labelId="expat-dependent-govt-fees"
                placeholder="0.00"
                value={employmentData.expatDependentGovtFees}
                onChange={(e) =>
                  dispatch({
                    type: EMPLOYMENT_SECTORS_ACTIONS.EXPAT_DEPENDENT_GOVT_FEES,
                    value: parseNumber(e.target.value),
                  })
                }
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <CurrencyInput
                currency={t("currency.SAR")}
                label={t("labels.educationSuppliesExpense")}
                labelId="education-supplies-expenses"
                placeholder="0.00"
                value={employmentData.educationSuppliesExpense}
                onChange={(e) =>
                  dispatch({
                    type: EMPLOYMENT_SECTORS_ACTIONS.EDUCATION_SUPPLIES_EXPENSE,
                    value: parseNumber(e.target.value),
                  })
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <CurrencyInput
                currency={t("currency.SAR")}
                label={t("labels.monthlyRemittance")}
                labelId="monthly-remittance"
                placeholder="0.00"
                value={employmentData.monthlyRemittance}
                onChange={(e) =>
                  dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.MONTHLY_REMITTANCE, value: parseNumber(e.target.value) })
                }
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <CurrencyInput
                currency={t("currency.SAR")}
                label={t("labels.otherMonthlyExpenses")}
                labelId="other-monthly-expenses"
                placeholder="0.00"
                value={employmentData.otherMonthlyExpense}
                onChange={(e) =>
                  dispatch({
                    type: EMPLOYMENT_SECTORS_ACTIONS.OTHER_MONTHLY_EXPENSE,
                    value: parseNumber(e.target.value),
                  })
                }
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion>
        <StyledAccordionSummary expandIcon={expensionIcon}>
          <Typography fontWeight="bold">{t("labels.numberOfDependants")}</Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <StyledQuantityInput
                onValueChange={(value) =>
                  dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.NUMBER_OF_DEPENDANTS_PUBLIC_SCHOOL, value })
                }
                label={t("labels.publicSchoolDependants")}
                labelId="dependents-number-public-school"
                numbersType={(i18n.language as "en" | "ar") || "en"}
                maxValue={10}
                value={employmentData.dependantsPublicSchool}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <StyledQuantityInput
                onValueChange={(value) =>
                  dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.NUMBER_OF_DEPENDANTS_PRIVATE_SCHOOL, value })
                }
                label={t("labels.privateSchoolDependants")}
                labelId="dependents-number-private-school"
                numbersType={(i18n.language as "en" | "ar") || "en"}
                maxValue={10}
                value={employmentData.dependantsPrivateSchool}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <StyledQuantityInput
                onValueChange={(value) =>
                  dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.NUMBER_OF_HOUSEHOLD_HELP, value })
                }
                label={t("labels.numberOfHouseholdHelp")}
                labelId="dependents-number-household-help"
                numbersType={(i18n.language as "en" | "ar") || "en"}
                maxValue={10}
                value={employmentData.householdHelp}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <StyledQuantityInput
                onValueChange={(value) =>
                  dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.NUMBER_OF_ALL_DEPENDANTS, value })
                }
                label={t("labels.numberOfAllDependants")}
                labelId="dependents-number-all"
                value={employmentData.allDependants}
                numbersType={(i18n.language as "en" | "ar") || "en"}
                maxValue={10}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </StyledAccordion>

      <Grid container spacing={2} marginTop="8px">
        <Grid item lg={6} xs={12}>
          <StyledRadioButtonGroup
            groupId="payment-responsible-group-lable"
            groupTitle={t("labels.resonsibleTitle")}
            value={employmentData.isResponsibleForPayment}
            textValues={yesNoValues}
            onChange={(e) =>
              dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.RESPONSIBLE_FOR_PAYMENT, value: e.target.value })
            }
          />
        </Grid>
        {employmentData.isResponsibleForPayment == "NO" && (
          <Grid item lg={6} xs={12}>
            <BaseInput
              type="text"
              value={employmentData.responsiblePersonName}
              onChange={(e) => dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.PERSON_RESPONSIBLE, value: e.target.value })}
              fullWidth
              label={t("labels.nameOfResponsiblePerson")}
              labelId="name-of-person"
              placeholder={t("labels.nameOfResponsiblePerson")}
            />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={2} marginTop="5px">
        <Grid item lg={6} xs={12}>
          <StyledRadioButtonGroup
            groupId="bread-winner-group-lable"
            groupTitle={t("labels.breadwinnerTitle")}
            value={employmentData.isBreadWinner}
            textValues={yesNoValues}
            onChange={(e) => dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.SOLE_BREADWINNER, value: e.target.value })}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <StyledRadioButtonGroup
            groupId="rdef-group-lable"
            groupTitle={t("labels.rdef")}
            value={employmentData.isRDEF}
            textValues={yesNoValues}
            onChange={(e) => dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.RDEF, value: e.target.value })}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} marginTop="8px">
        <Grid item lg={6} xs={12}>
          <StyledDropdown
            placeholder={t("labels.chooseAccommodation")}
            optionsArray={translatedOptions}
            onClick={(value) => dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.ACCOMODATION_TYPE, value })}
            value={t(employmentData.accomodationType)}
            label={t("labels.accommodationType")}
            labelId="accommodationType"
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <StyledRadioButtonGroup
            groupId="home-owner-group-lable"
            groupTitle={t("labels.homeOwnerTitle")}
            value={employmentData.isHomeOwner}
            textValues={yesNoValues}
            onChange={(e) => dispatch({ type: EMPLOYMENT_SECTORS_ACTIONS.HOME_OWNER, value: e.target.value })}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} marginTop={5}>
        <Grid
          container
          item
          lg={7}
          xs={12}
        >
          <Grid item xs={2} lg={1}>
            <Checkbox checked={consent} onChange={handleSetConsent} />
          </Grid>
          <Grid item xs={10} lg={11}>
            <Typography onClick={() => setConsent((value) => !value)}>
              {t("employmentDetails.consent")}{" "}
              <Link component="button" onClick={handleOpenConsentDialog}>
                {t("employmentDetails.fullConsent")}
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          lg={5}
          justifyContent="space-between"
          alignItems="center"
          sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
        >
          <Button tertiary color="secondary" onClick={history.goBack}>
            {t("buttons.back") as string}
          </Button>
          <StyledContinueButton
            sx={{ width: { xs: "150px", sm: "180px" } }}
            onClick={handleContinueClick}
            disabled={!consent || employmentData.accomodationType == ""}
          >
            {t("buttons.continue") as string}
          </StyledContinueButton>
        </Grid>
      </Grid>
      <Grid
        item
        container
        spacing={2}
        xs={12}
        justifyContent="space-between"
        alignItems="center"
        marginTop="30px"
        sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}
      >
        <Button
          tertiary
          color="secondary"
          sx={{ width: { xs: "40%", sm: "40%" } }}
          onClick={history.goBack}
        >
          {"Back"}
        </Button>
        <StyledContinueButton
          sx={{ width: { xs: "40%", sm: "40%" } }}
          onClick={handleContinueClick}
          disabled={!consent || employmentData.accomodationType == ""}
        >
          {t("buttons.continue") as string}
        </StyledContinueButton>
      </Grid>

      {isDialogOpen && (
        <EmploymentDialogs
          {...dialogInfo}
          onReopenDialog={handleOpenDialog}
          closeDialog={handleCloseDialog}
          isDialogOpen={isDialogOpen}
          openDialog={openDialog}
        />
      )}
    </>
  );
};
