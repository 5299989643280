import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { IDialogContents } from "@appTypes/dialogs";
import { IOTPInfoResponse } from "@appTypes/responses";
import { Dialog } from "@components/Dialogs/BasicDialog";
import { ACCOUNT_IS_LOCKED, SetOTPComponent } from "@components/SetOTP";
import { TextWithCountdownTimer } from "@components/TextWithCountdownTimer";
import * as httpCodes from "@constants/httpStatuses";
import { dialogContents } from "@constants/popups";
import { DEFAULT_USER_REGISTRATION_INFO, DEFAULT_USER_INFO } from "@constants/sessionStorageDefaults";
import { MERCHANT_DATA_KEY, REGISTRATION_KEY, USER_INFO_KEY } from "@constants/sessionStorageKeys";
import { siteMap } from "@constants/siteMap";
import { useCustomerInfo } from "@pages/SetOTP/hooks/useCustomerInfo";
import { authorizationStore } from "@store/authorization";
import { CHECK_SRS_DIALOG_TYPES } from "@utils/hooks/useCheckSRS/useCheckSRS";
import { SAFETY_CHECK_DIALOG_TYPES } from "@utils/hooks/useCheckSRS/useSafetyCheck";
import { useDialog } from "@utils/hooks/useDialog";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { useGenerateOTP } from "@utils/network/useGenerateOTP";
import { logoutWithRedirect } from "@utils/services/Authentication/logoutWithRedirect";
import { ITimeObject } from "@utils/services/Timer/timer.types";

import { HandleContinueArguments } from "./SetOTP.types";
import { OTP_DIALOG_TYPES } from "./constants/dialogs";
import { useGetOTPInfo } from "./hooks/useGetOTPInfo";
import { useGetPhoneInfo } from "./hooks/useGetPhoneInfo";
import { useHandleCustomerStatus, IUseHandleCustomerStatusResult } from "./hooks/useHandleCustomerStatus";
import { useVerifyOTP } from "./hooks/useVerifyOTP";

export const SetOTP: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [registrationData, setUserDataToStorage] = useSessionStorageState(
    REGISTRATION_KEY,
    DEFAULT_USER_REGISTRATION_INFO,
  );
  const [userInfo, setUserInfoToStorage] = useSessionStorageState(USER_INFO_KEY, DEFAULT_USER_INFO);
  const [isDialogOpen, closeDialog, openDialog] = useDialog();
  const [currentDialogContent, setCurrentDialogContent] = useState<
    // eslint-disable-next-line
    (IDialogContents & { lastAction?: (params?: any) => Promise<IUseHandleCustomerStatusResult> }) | undefined
  >();
  const [dialogType, setDialogType] = useState("");
  const [codeStatusData, setCodeStatusData] = useState<IOTPInfoResponse | undefined>();
  const [redirectionTimer] = useState<ITimeObject<number>>({ hours: 0, minutes: 0, seconds: 15 });
  const [merchantStorageValue] = useSessionStorageState(MERCHANT_DATA_KEY, {
    checkoutToken: "",
    merchantUrl: "",
  });

  const [getNewOTPCode] = useGenerateOTP();
  const [getOTPCodeStatus] = useGetOTPInfo(); // GET otp/info
  const [verifyOTPCode] = useVerifyOTP();
  const { getPhoneInfo } = useGetPhoneInfo();
  const { getCustomerInfo } = useCustomerInfo();
  const { handleCustomerStatus } = useHandleCustomerStatus();
  // TODO: OMG - It's needed to save handleContinue arguments for oportunity to call this one here when user uses retry button
  const handleContinueArgs = useRef<{
    args: HandleContinueArguments | null;
  }>({ args: null });

  const openUnexpectedErrorDialog = (dialogType = OTP_DIALOG_TYPES.SOMETHING_WENT_WRONG) => {
    setDialogType(dialogType);
    setCurrentDialogContent(dialogContents.somethingWentWrong);
    openDialog();
  };
  const handleOtpVerifyRequestFail = useCallback(() => {
    setDialogType(OTP_DIALOG_TYPES.OTP_VERIFY_REQUEST_IS_FAILED);
    setCurrentDialogContent(dialogContents.somethingWentWrong);
    openDialog();
  }, [setDialogType, setCurrentDialogContent, openDialog]);

  const getPhoneNumber = async () => {
    try {
      const { data, status } = await getPhoneInfo();

      if (status === httpCodes.OK) {
        setUserInfoToStorage({
          ...userInfo,
          phoneNumber: data.number,
        });
      } else {
        openUnexpectedErrorDialog(OTP_DIALOG_TYPES.SOMETHING_WRONG_WITH_USER_DATA);
      }
    } catch (e) {
      openUnexpectedErrorDialog(OTP_DIALOG_TYPES.SOMETHING_WRONG_WITH_USER_DATA);
    }
  };

  useEffect(() => {
    const getOTPInfo = async () => {
      try {
        const { data, status } = await getOTPCodeStatus();
        if (status === httpCodes.OK) {
          setCodeStatusData(data);
        } else {
          openUnexpectedErrorDialog();
        }
      } catch (e) {
        openUnexpectedErrorDialog();
      }
    };

    getPhoneNumber();
    getOTPInfo();
  }, []);

  const onGoBack = () => {
    authorizationStore.setIsLogged(false);
  };

  const redirectToMerchantWebsite = useCallback(async () => {
    await logoutWithRedirect(merchantStorageValue.merchantUrl);
  }, [merchantStorageValue]);

  const modifySrsDialog = useCallback(
    (dialog: IDialogContents, dialogType: CHECK_SRS_DIALOG_TYPES | SAFETY_CHECK_DIALOG_TYPES): IDialogContents => {
      return dialogType === CHECK_SRS_DIALOG_TYPES.SRS_CHECK_HAVE_NOT_PASSED
        ? {
          ...dialog,
          description: (
            <TextWithCountdownTimer
              countdownTimer={redirectionTimer}
              textKey={dialog.description as string}
              onTimeoutCallback={redirectToMerchantWebsite}
            />
          ),
        }
        : dialog;
    },
    [redirectToMerchantWebsite, redirectionTimer],
  );

  const handleRedirectResult = useCallback((result: IUseHandleCustomerStatusResult | undefined) => {
    if (result?.isSuccess === false && result.dialog && result.dialogType) {
      const { dialog, dialogType, lastAction } = result;
      setDialogType(dialogType);
      setCurrentDialogContent({ ...modifySrsDialog(dialog, dialogType), lastAction });
      openDialog();
    }
  }, []);

  const performRedirect = useCallback(async (status: string) => {
    const result = await handleCustomerStatus(status);

    handleRedirectResult(result);
  }, []);

  const handleContinue = useCallback<(...args: HandleContinueArguments) => void>(
    async (otpInputValue, setInputError, setOtpMessage, handleResponse) => {
      // TODO: OMG - It's needed to save handleContinue arguments for oportunity to call this one here when user uses retry button
      handleContinueArgs.current.args = [otpInputValue, setInputError, setOtpMessage, handleResponse];

      try {
        const { data, status } = await verifyOTPCode(otpInputValue, merchantStorageValue.checkoutToken);

        switch (status) {
        case httpCodes.OK: {
          const isResponseSuccess = handleResponse(data);

          if (!isResponseSuccess) {
            return;
          }

          if (data.result === "true") {
            const { isSuccess, customerData } = await getCustomerInfo();

            if (customerData && isSuccess) {
              await setUserInfoToStorage({
                ...userInfo,
                status: customerData.status,
                otpGeneratedAt: customerData.otpGeneratedAt,
                englishFirstName: customerData.englishFirstName,
                arabicFirstName: customerData.arabicFirstName,
              });
              await setUserDataToStorage({
                ...registrationData,
                form: {
                  ...registrationData.form,
                  BIRTH_DATE: customerData.dateOfBirth,
                },
              });

              if (data.token && customerData) {
                authorizationStore.setAuthorization(data.token);

                // TODO 2243: Check user blocked status within  task 2243
                performRedirect(customerData.status);
              }
            } else {
              handleOtpVerifyRequestFail();
            }
          } else if (data.result === "false") {
            setInputError(true);
            setOtpMessage("errors.WRONG_OTP");
          } else {
            handleOtpVerifyRequestFail();
          }
          break;
        }
        default:
          handleOtpVerifyRequestFail();
        }
      } catch (error) {
        handleOtpVerifyRequestFail();
        return;
      }
    },
  [userInfo],
  );

  const sendRequestForOtpCodeStatus = useCallback(async () => {
    try {
      const { data, status } = await getOTPCodeStatus();
      switch (status) {
      case httpCodes.OK:
        setCodeStatusData(data);
        break;
      default:
        openUnexpectedErrorDialog();
        break;
      }
    } catch (error) {
      openUnexpectedErrorDialog();
    }
  }, []);

  const handleAgreeClick = async () => {
    switch (dialogType) {
    case ACCOUNT_IS_LOCKED: {
      //logout
      authorizationStore.setIsLogged(false);
      history.push(siteMap.LogInPage.path);
      break;
    }
    case OTP_DIALOG_TYPES.SOMETHING_WRONG_WITH_USER_DATA: {
      getPhoneNumber();
      break;
    }
    case OTP_DIALOG_TYPES.SOMETHING_WENT_WRONG: {
      sendRequestForOtpCodeStatus();
      break;
    }
    case OTP_DIALOG_TYPES.OTP_VERIFY_REQUEST_IS_FAILED: {
      const { args } = handleContinueArgs.current;
      if (args) {
        handleContinue(...args);
      }
      break;
    }
    case CHECK_SRS_DIALOG_TYPES.SRS_CHECK_FAILED: {
      if (currentDialogContent?.lastAction) {
        const result = await currentDialogContent?.lastAction(true);
        handleRedirectResult(result);
      }
      return;
    }
    case CHECK_SRS_DIALOG_TYPES.SRS_CHECK_FINALLY_FAILED:
    case CHECK_SRS_DIALOG_TYPES.SRS_CHECK_HAVE_NOT_PASSED: {
      redirectToMerchantWebsite();
      break;
    }
    case OTP_DIALOG_TYPES.PROVIDE_OFFER_ERROR: {
      logoutWithRedirect(merchantStorageValue.merchantUrl);
      break;
    }
    case SAFETY_CHECK_DIALOG_TYPES.SAFETY_CHECK_DIALOG_REQUEST_FAILED: {
      if (currentDialogContent?.lastAction) {
        const result = await currentDialogContent?.lastAction();
        handleRedirectResult(result);
      }
      return;
    }
    }
    closeDialog();
  };

  return (
    <>
      <SetOTPComponent
        title={"pageHeader.codeVerification"}
        subtitle={t("pageContent.codeSentToNumber")}
        handleResendOTP={getNewOTPCode}
        pinLength={4}
        onGoBack={onGoBack}
        handleContinue={handleContinue}
        codeStatusData={codeStatusData}
        dialogMethods={{ openUnexpectedErrorDialog, setCurrentDialogContent, openDialog, setDialogType }}
      />
      {currentDialogContent && (
        <Dialog
          dialog={currentDialogContent}
          open={isDialogOpen}
          onAgree={handleAgreeClick}
          onClose={closeDialog}
        />
      )}
    </>
  );
};
