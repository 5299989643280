import { Typography, styled, Grid } from "@mui/material";

export const RequestAmountTxt = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  [theme.breakpoints.down("sm")]: {},
}));

export const AmountTxt = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: "#425CC7",
  padding: "0px 20px",
  [theme.breakpoints.down("sm")]: {},
}));

export const PricingItem = styled(Grid)(({ theme }) => ({
  padding: "0 10px",
  width: "100%",
  [theme.breakpoints.down("sm")]: {},
}));

export const PricingItemBox = styled(Grid)(({ theme }) => ({
  padding: 15,
  borderRadius: 5,
  border: "solid #E8E7E7 2px",
  cursor: "pointer",
  "&:hover": {
    border: "solid #425CC7 2px",
    backgroundColor: "#F4F5FC",
  },
  "&.checked": {
    border: "solid #425CC7 2px",
    backgroundColor: "#F4F5FC",
  },
  [theme.breakpoints.down("sm")]: {},
}));

export const PricingMonths = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: "#425CC7",
  fontSize: 20,
  [theme.breakpoints.down("sm")]: {},
}));

export const PricingCost = styled(Typography)(({ theme }) => ({
  fontWeight: 200,
  color: "#000",
  fontSize: 16,
  paddingTop: 10,
  [theme.breakpoints.down("sm")]: {},
}));
