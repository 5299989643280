import { IVerifyOTPResponse } from "@appTypes/responses";
import { IAxiosResponseWithError, network } from "@utils/network/network";

type useVerifyOTPReturnType = [
  verifyOTPCode: (otpCode: string, checkoutToken?: string) => Promise<IAxiosResponseWithError<IVerifyOTPResponse>>,
];

export const useVerifyOTP = (): useVerifyOTPReturnType => {
  const verifyOTPCode = (
    otpCode: string,
    checkoutToken?: string,
  ): Promise<IAxiosResponseWithError<IVerifyOTPResponse>> => {
    const otpData = {
      otpCode,
    };

    return network.request<IVerifyOTPResponse>("connect", "otp/verify", {
      method: "POST",
      data: otpData,
      params: {
        ...(checkoutToken && {
          checkoutToken,
        }),
      },
    });
  };

  return [verifyOTPCode];
};
