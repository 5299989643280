import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider, createTheme } from "@mui/material";
import { theme } from "@ui-kit";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";

import { AppLogOutContainer } from "@components/AppLogOutContainer";
import { AUTHENTICATION_KEY } from "@constants/sessionStorageKeys";
import { AppRouter } from "@pages/AppRouter";
import { authorizationStore, useAuthorization } from "@store/authorization";
import { languageService, languages, useLanguageCode } from "@store/languageService";
// import rtlPlugin from "stylis-plugin-rtl";
import { useSessionStorageState } from "@utils/hooks/useSessionStorageState";
import { FeaturesProvider } from "@utils/services/Features";
import { PopupProvider } from "@utils/services/Popups/PopupProvider";

// TODO: Add plugin [rtlPlugin as any] for RTL
// After adding check layouts in entire application, it uses in StepperArabic
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [],
});

const cacheLtr = createCache({
  key: "muiltr",
  stylisPlugins: [],
});

const arabTheme = createTheme({
  ...theme,
  direction: "rtl",
  typography: { ...theme.typography, fontFamily: "Cairo,Roboto" },
});
for (const prop in arabTheme.typography) {
  // @ts-ignore
  if (Object.keys(arabTheme.typography[prop]).includes("fontFamily")) {
    // @ts-ignore
    arabTheme.typography[prop].fontFamily = "Cairo";
  }
}

const updateFonts = (langCode: languages) => {
  const styles = `
    body, body > *{
      font-family: "Cairo";
    }
  `;

  const styleSheet = document.createElement("style");
  styleSheet.type = "text/css";
  styleSheet.innerText = styles;
  styleSheet.id = "arabic_language_font_style";

  if (langCode === languages.AR) {
    document.head.appendChild(styleSheet);
    document.body.dir = "rtl";
  } else {
    document.getElementById("arabic_language_font_style")?.remove();
    document.body.dir = "ltr";
  }
};

const App: React.FC = () => {
  const [, setUserToSessionStorage] = useSessionStorageState(AUTHENTICATION_KEY, {
    access_token: authorizationStore.getValue().access_token,
    expires_in: authorizationStore.getValue().expires_in,
    token_type: authorizationStore.getValue().token_type,
    scope: authorizationStore.getValue().scope,
    refresh_token: authorizationStore.getValue().refresh_token,
  });
  useAuthorization(setUserToSessionStorage);

  const [chosenTheme, setChosenTheme] = useState(theme);
  const [chosenCache, setChosenCache] = useState(cacheLtr);
  const [currentLanguage, setLanguage] = useState<undefined | languages>(i18next.language as languages);

  useLanguageCode(setLanguage);

  useEffect(() => {
    setChosenTheme(currentLanguage === languages.EN ? theme : arabTheme);
    setChosenCache(currentLanguage === languages.EN ? cacheLtr : cacheRtl);

    if (!languageService.getValue().languageCode && currentLanguage) {
      // Set default language as languages.EN
      const lang = Object.values(languages).includes(currentLanguage) ? currentLanguage : languages.EN;

      languageService.setLanguage(lang);
    }
    updateFonts(currentLanguage as languages);
  }, [currentLanguage]);

  return (
    <BrowserRouter>
      <AppLogOutContainer>
        <CacheProvider value={chosenCache}>
          <ThemeProvider theme={chosenTheme}>
            <PopupProvider>
              <FeaturesProvider>
                <AppRouter />
              </FeaturesProvider>
            </PopupProvider>
          </ThemeProvider>
        </CacheProvider>
      </AppLogOutContainer>
    </BrowserRouter>
  );
};

export default App;
