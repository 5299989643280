import { employmentSectorPageMap } from "@modules/EmploymentSector/constants/pageMap";
import { registrationSiteMap } from "@modules/Registration/pageMap";
import { webCheckoutPageMap } from "@modules/WebCheckout/constants/pageMap";

export const siteMap = {
  HomePage: {
    path: "/",
  },
  LogInPage: {
    path: "/login",
  },
  ResetPasswordPage: {
    path: "/login/reset-password",
  },
  ForgotPasswordPage: {
    path: "/login/forgot-password",
  },
  ForgotPasswordOTPPage: {
    path: "/login/forgot-password/otp",
  },
  LoginOTPPage: {
    path: "/login/otp",
  },
  ...webCheckoutPageMap,
  ...employmentSectorPageMap,
  ...registrationSiteMap,
  ConfirmationPage: {
    path: "/confirmation",
  },
  Error404Page: {
    path: "/404error",
  },
};
