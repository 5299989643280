const NO_TRANSLATION = "no translation";

export const TRANSLATIONS_ARAB = {
  hello: "أهلا يا صديقي",
  additionalPart: "جزء إضافي",
  loading: "...Loading",
  error404Header: "عفواً!",
  error404: "خطأ ٤٠٤. للاسف الصفحه التي تبحث عنها غير موجوده. ",
  units: {
    month: "شهر",
    month_one: "شهر",
    month_other: "شهور",
    trial: "ﻤﺤﺎولة",
    trial_one: "ﻤﺤﺎولة",
    trial_other: "ﻣﺤﺎوﻟة",
  },
  currency: {
    SAR: "ريال",
  },
  buttons: {
    logIn: "تسجيل دخول",
    logInSingle: "تسجيل دخول",
    continue: "استمرار",
    goBack: "رجوع",
    ok: NO_TRANSLATION,
    createAccount: "إنشاء حساب",
    resend: "أعد الإرسال",
    exit: "خروج",
    tryAgain: "اﻋﺪ اﻟﻤﺤﺎوﻟﻪ",
    cancel: "رجوع", // Translation - back
    yesCancel: "نعم",
    no: "لا",
    yes: "نعم",
    backToStore: "اﻟﺮﺟﻮع إلى اﻟﻤﺘﺠﺮ",
    goToStore: "رﺟﻮع اﻟﻰ اﻟﻤﺘﺠﺮ",
    downloadApp: "تحميل تطبيق ٩٩",
    continueHere: "استمرار هنا",
    continueWithApp: "اﺳﺘﻤﺮاﺭ ﺑﺎﻟﺘﻄﺒﻴﻖ",
    goToTheApp: "تابع الى التطبيق",
    getStarted: "لنبدأ",
    goToContract: "اذهب الى العقد",
    reject: "رفض",
    sign: "توقيع",
    updateDOB: "ﺗﺤﺪﻳﺚ ﺗﺎرﻳﺦ اﻟﻤﻴﻠﺎد",
    callBack: "اتصل مرة أخرى",
    selectSector: "اختر القطاع",
    back: "رجوع",
    accept: "قبول",
  },
  labels: {
    passwordRequire: "الرقم السري يجب ان يحتوي علي:",
    password: "الرقم السري",
    passwordRepeat: "تأكيد الرقم السري ",
    email: "البريد الإلكتروني",
    mobile: "رقم الهاتف",
    nationalIdOrIQAMA: "الإقامة أو رقم الهوية",
    birthDate: "تاريخ الميلاد",
    recoveryCode: NO_TRANSLATION,
    retryIn: "أعد المحاولة ﺑﻌﺪ",
    passwordStrength: {
      Low: "ضعيف",
      Medium: "ﻢﺗﻮﺴﻃ",
      Great: "قوي",
    },
    comingSoon: "قريبا",
    accommodationType: "نوع الإقامة",
    chooseAccommodation: "اختر نوع الاقامه",
    dependentsNumber: "عدد المعالين",
    monthlyExpenses: "المصاريف الشهرية",
    ownHouse: "مالك",
    rental: "مستأجر",
    setBirthday: "ﺣﺪد ﺗﺎرﻳﺦ اﻟﻤﻴﻠﺎد",
    remaining: "ﻣﺘﺒﻘﻲ",
    selectSector: "سأكون مسؤولاً عن سداد المدفوعات",
    monthlyIncome: "الدخل الشهري",
    monthlyRent: "الإيجار الشهري",
    householdWage: "العمالة المنزلية",
    utilityExpense: "النفقات العامة",
    insurancePremium: "التأمين",
    transportExpenses: "مصاريف النقل",
    healthcareExpenses: "المصاريف الصحية",
    communicationExpenses: "مصاريف الاتصال",
    foodAndLivingExpenses: "مصاريف الغذاء",
    expatDependentGovFees: 'الرسوم الحكومية "لغير السعودين"',
    educationSuppliesExpense: "مصاريف التعليم",
    monthlyRemittance: "حوالات شهرية من أجل إعالة العائلة",
    otherMonthlyExpenses: "مصاريف أخرى",
    numberOfDependants: "عدد المعولين",
    publicSchoolDependants: "عدد المعولين ف المدارس العامة",
    privateSchoolDependants: "عدد المعولين في المدارس الخاصة",
    numberOfHouseholdHelp: "عدد المساعدة المنزلية",
    numberOfAllDependants: "عدد جميع المعولين (العدد يجب أن يجمع جميع المعولين أعلاه وأيضاً ما لم يذكروا)",
    resonsibleTitle: "سأكون مسؤولاً عن سداد المدفوعات",
    nameOfResponsiblePerson: "اسم الشخص المسؤول عن المدفوعات",
    breadwinnerTitle: "هل أنت المعيل الوحيد للأسرة؟",
    rdef: "هل تستخدم صندوق التنمية العقارية",
    homeOwnerTitle: "هل انت صاحب البيت؟",
  },
  placeholders: {
    email: "البريد الإلكتروني",
    mobileNumber: "رقم الهاتف",
    nationalIdOrIQAMA: "الإقامة أو رقم الهوية",
    birthDate: "ﺗﺎرﻳﺦ اﻟﻤﻴﻠﺎد",
    password: "اﻟﺮﻗﻢ اﻟﺴﺮي",
  },
  additionalInfo: {
    didntGetCode: NO_TRANSLATION,
    forgotPassword: "ﻫﻞ ﻧﺴﻴﺖ اﻟﺮﻗﻢ اﻟﺴﺮي؟",
  },
  errors: {
    NATIONAL_ID_NOT_VALID_ERROR: "اﻟﺮﻗﻢ ﺧﺎﻃﺊ. ﻣﻦ ﻓﻀﻠﻚ اﻋﺪ ادﺧﺎﻟﻪ ﺑﻄﺮﻳﻘﻪ ﺻﺤﻴﺤﻪ",
    NATIONAL_ID_IS_MANDATORY_ERROR: "ﻳﺠﺐ ﻋﻠﻴﻚ ادﺧﺎل ﻫﺬا اﻟﺮﻗﻢ",
    WRONG_PASSWORD_ERROR: "اﻟﺮﻗﻢ ﺧﺎﻃﺊ. ﻣﻦ ﻓﻀﻠﻚ اﻋﺪ ادﺧﺎﻟﻪ ﺑﻄﺮﻳﻘﻪ ﺻﺤﻴﺤﻪ",
    EMAIL_NOT_VALID_ERROR: "هذا البريد غير صحيح",
    UPPERCASE_one: "ﻋﻠﻰ اﻟﺎﻗﻞ ﺣﺮف ﻛﺒﻴﺮ",
    UPPERCASE_two: "على الاقل حرفين كبيرين",
    LOWERCASE_one: "على الاقل حرف صغير",
    LOWERCASE_two: "على الاقل حرفين صغيرين",
    NUMBER: "ﻋﻠﻲ اﻟﺎﻗﻞ رﻗﻢ",
    SPECIAL_SYMBOL: `على الاقل رمز واحد \n )~! @ # $% ^ & * _- + = '| \\ () {} []: "" <>,.? /(`,
    SYMBOLS: " على الاقل {{ numberOfSigns }} حروف ",
    PHONE_NOT_VALID_ERROR: "ﻣﻦ ﻓﻀﻠﻚ أدﺧﻞ ﻧﻔﺲ رﻗﻢ اﻟﻤﻮﺑﻴﻞ اﻟﻤﺴﺠﻞ ﻓﻲ أﺑﺸﺮ",
    BIRTH_DATE_UNDERAGE_ERROR: "ﺗﺎرﻳﺦ ﻏﻴﺮ ﺻﺤﻴﺢ. ﻣﺴﺘﺨﺪم اﻟﺘﻄﺒﻴﻖ ﻳﺠﺐ أن ﻳﻜﻮن ﺳﻨﻪ ٢١ ﻋﺎﻣﺎً ﻋﻠﻲ الأﻗﻞ",
    CODE_IS_INVALID: NO_TRANSLATION,
    OTP_ATTEMPTS_EXCEEDED24: "لقد تجاوزت المحاولات المسموح بها. من فضلك انتظر قبل المحاوله التاليه ",
    OTP_ATTEMPTS_EXCEEDED: "ﻟﻘﺪ ﺗﺠﺎوزت اﻟﻤﺤﺎوﻟﺎت اﻟﻤﺴﻤﻮح ﺑﻬﺎ",
    ACCOUNT_LOCKED_ATTEMPTS_EXCEEDED: NO_TRANSLATION,
    WRONG_OTP: "اﻟﺮﻗﻢ ﺧﺎﻃﺊ.",
    LOGIN_ATTEMPTS_EXCEEDED: "ﻳﺠﺐ اﻟﺎﻧﺘﻈﺎر ٥ دﻗﺎﺋﻖ ﺑﺴﺒﺐ ﻛﺜﺮه اﻟﻤﺤﺎوﻟﺎت اﻟﺨﺎﻃﺌﻪ",
    PASSWORD_DONT_MATCH: "الرقم السري غير متطابق",
    PASSWORD_DONT_MATCH_LOWEST_CRITERIA: "اﻟﺮﻗﻢ اﻟﺴﺮي ﻟﺎ ﻳﻮاﻓﻖ اﻗﻞ اﻟﺸﺮﻮﻃ",
    DATE_OF_BIRTH_IS_INCORRECT: "ﻣﻦ ﻓﻀﻠﻚ أدﺧﻞ ﺗﺎرﻳﺦ اﻟﻤﻴﻠﺎد ﺑﺸﻜﻞ ﺻﺤﻴﺢ",
  },
  pageHeader: {
    createAccount: {
      create: "إنشاء",
      account: "حساب",
    },
    login: {
      login: "تسجيل دخول في",
      account: "",
    },
    employmentSector: `اختر قطاع التوظيف`,
    setupPassword: "انشاء الرقم السرى",
    forgotPassword: "نسيت الرقم السري",
    verifyNewPassword: "أدﺧﻞ رﻣﺰ اﻟﺘﺤﻘﻖ",
    codeVerification: "أدﺧﻞ رﻣﺰ اﻟﺘﺤﻘﻖ",
    employmentDetails: "ادخل البيانات الوظيفيه",
    offerDetails: "عرضنا لك من أجل تقسيط مبلغ",
    offerDetailsMobile: "ﻋﺮوﺿﻨﺎ ﻣﻦ اﺟﻠﻚ ﻟﺘﻘﺴﻴﻂ",
  },
  pageContent: {
    dontHaveAccount: "ليس لديك حساب؟",
    alreadyHaveAccount: "هل لديك حساب بالفعل؟",
    codeSentTo: "الرجاء إدخال الرمز الذي تم إرساله إليك من قبل أبشر إلى هاتفك المسجّل لدى الحكومة.",
    codeSentToNumber: "ﻣﻦ ﻓﻀﻠﻚ أدﺧﻞ الرمز السري المرسل  إلى",
    newPasswordCodeSentToNumber: "ﻣﻦ ﻓﻀﻠﻚ أدﺧﻞ الرمز السري المرسل  إلى",
    didntReceiveCode: "ﻟﻢ ﺗﺴﺘﻘﺒﻞ الرمز اﻟﺴري؟",
    resendCodeIn: "اعد الارسال خلال",
    privateSector: "القطاع الخاص",
    governmentSector: "القطاع الحكومي",
    verifyDataToResetPassword: "تحقق من بياناتك الشخصيه لانشاء رقم سري جديد",
    newCodeSent: "تم ارسال رمز جديد",
    offerDetails: {
      firstInstallment: "القسط الاول",
      nextInstallment: "الاقساط التاليه",
      lastInstallment: "القسط الاخير",
      financeAmount: "القيمه الماليه",
    },
    offerPayments: {
      chooseOffer: "اختر كيف تريد الدفع",
      offerLabel: "قسط مشترياتك بدفعات ثابته بقيمه {{cost}} ريال شهريا",
      notEligibleForOffer: "نأسف هذا البرنامج غير متاح لك حاليالاختياره",
      onlyMonthly: " ﻒﻘﻃ ﺶﻫﺮﻳ",
      select3_6monthsLabel: "دﻓﻌﺎت ﻣﺘﺴﺎوﻳﻪ ﻗﺴﻂ ﻣﺸﺘﺮﻳﺎﺗﻚ ﻋﻠﻰ ٣ اﻟﻰ ٦",
      select3_6monthsTemporaryLabel: "ﻏﻴﺮ ﻣﺘﺎح",
      comingSoon: "قريبا...",
      select3_6monthsHeader: "سدد على ٣-٦",
      payIn: "دﻓﻌﺎت",
      noEligiblePrograms: "للاسف لم تتوافق مع اي برنامج متاح لدي كوارا حاليا",
      contactToSupport: "للاسف لم تتوافق مع اي برنامج متاح لدي كوارا حاليا <0>{{email}}</0>",
    },
  },
  dialogTitle: {
    threeDSecureVerification: "سيتم التحقق من الكارت باستخدام طريقه الامان ثلاثيه الابعاد",
    accountLocked: "ﺗﻢ اﻏﻠﺎق اﻟﺤﺴﺎب ﻣﺆﻗﺘﺎ",
    userAlreadyRegistered: "هذا المستخدم مسجل مسبقاً",
    userAlreadyRegistered_deprecated: "هذا المستخدم مسجل بالفعل",
    unableToVerify: "ﻣﻦ ﻫﻮﻳﺘﻚ ﻟﺴﻮء اﻟﺤﻆ ﻟﻢ ﻧﺴﺘﻄﻊ اﻟﺘﺤﻘﻖ",
    somethingWentWrong: "ﺣﺪث ﺷﺊ ﺧﻄﺄ",
    privateSectorVerificationFailed: "فشل التحقق من القطاع الخاص ",
    governmentSectorVerificationFailed: "ﻓﺸﻞ اﻟﺘﺤﻘﻖ ﻣﻦ اﻟﻘﻄﺎع اﻟﺤﻜﻮﻣﻲ",
    incorrectData: "بيانات غير صحيحه",
    identityVerifying: NO_TRANSLATION,
    success: "نجاح!",
    unableVerifyCardDetails: NO_TRANSLATION,
    incorrectCardDetails: "بيانات الكارت غير صحيحه",
    balanceInsufficient: "رصيد الكارت غير كافي",
    confirmCancelQuestion: "هل أنت متأكد من الإلغاء؟",
    loanOfferExpired: "نأسف، انتهت صلاحية العرض",
    couldNotProvideOffer: `للاسف لم نستطع توفير قرض لك`,
    didntMeetFinanceCreditCriteria: "اﻟﻤﺘﻄﻠﺒﺎت اﻟﻤﺎﻟﻴﻪ ﻟﻜﻮاﺭﺍ ﻟﻠﺎﺳﻒ ﺑﻴﺎﻧﺎﺗﻚ ﻟﺎ ﺗﺘﻮاﻓﻖ ﻣﻊ",
    pleaseDownloadApp: "من فضلك قم بتحميل التطبيق",
    rejectContract: "هل انت متأكد انك تريد رفض العقد؟",
    srsCheckHaveNotPassed: "نأسف! بياناتك المالية لا تتوافق مع متطلبات كوارا للتمويل",
    wrongDOB: "من فضلك أدخل تاريخ الميلاد الصحيح طبقاً للمعلومات التالية",
    successfulCallbackRequest: "تم إرسال الطلب بنجاح!",
  },
  dialogContent: {
    dataDoesntExist: "ﻫﺬه اﻟﺒﻴﺎﻧﺎت ﻏﻴﺮ ﻣﻮﺟﻮده. ﻣﻦ ﻓﻀﻠﻚ اﺿﻐﻂ رﺟﻮع وﺣﺎول ﻣﺮه اﺧﺮي",
    accountLocked: " ﻟﺘﻔﻌﻴﻞاﻟﺤﺴﺎب، ﻣﻦ ﻓﻀﻠﻚﺗﻮاﺻﻞﻣﻌﻨﺎ ﻋﻠﻲ <0>{{email}}</0>",
    checkProvidedData:
      "ﻣﻦ ﻓﻀﻠﻚ ﺗﺄﻛﺪ ﻣﻦ اﻟﺒﻴﺎﻧﺎت اﻟﻤﺪﺧﻠﻪ اﻧﻬﺎ ﻣﺘﻮاﻓﻘﻪ ﻣﻊ اﻟﺒﻴﺎﻧﺎت اﻟﺤﻜﻮﻣﻴﻪ\n" + "وﺍﻳﻀﺎ ﻳﻤﻜﻨﻚ اﻟﺘﻮاﺻﻞ ﻣﻌﻨﺎ ﻋﻠﻲ ",
    requestWasntProcessed: "ﻣﻦ ﻓﻀﻠﻚ اﻋﺪ اﻟﻤﺤﺎوﻟﻪ ﺑﺴﺒﺐ ﺣﺪث ﻏﻴﺮ ﻣﺘﻮﻗﻊ ﻃﻠﺒﻚ ﻟﻢ ﻳﺘﻢ ﺗﻨﻔﻴﺬه.",
    contactForMoreOptions: `اذا كنت تريد معرفه خيارات اكثر, من فضلك تواصل معنا علي \n<0>{{ email }}</0>`,
    cardAddSuccess: "تم اضافه بيانات الكارت بنجاح",
    unableProcessRequest: "ﻣﻦ ﻓﻀﻠﻚ اﻋﺪ اﻟﻤﺤﺎوﻟﻪ ﺑﺴﺒﺐ ﺣﺪث ﻏﻴﺮ ﻣﺘﻮﻗﻊ ﻃﻠﺒﻚ ﻟﻢ ﻳﺘﻢ ﺗﻨﻔﻴﺬه.",
    cardDetailsDoesntMatch: "بيانات الكارت لا تتوافق. من فضلك تأكد من سلامه البيانات واعد المحاوله.",
    cancelCardDetails: "اذا تريد الغاء العرض من فضلك اضغط نعم.",
    unableDueInsufficientBalance:
      "لم تستطع اجراء المعامله نظرا لعدم كفايه رصيد الكارت.من فضلك تأكد من وجود رصيد كافي فى الكارت واعد المحاوله.",
    cancelOffer: 'اضغط على "نعم." للتأكيد.',
    offerExpired: "من فضلك قم بالعودة إلى المتجر وسجّل مرة أخرى ليصلك عرض جديد",
    exitOrContact: `من فضلك غادر او تواصل معنا علي\n<0>{{ email }}</0>`,
    youWillBeRedirectedToTheStore: `ﺳﻮف ﻳﺘﻢ ﺗﺤﻮﻳﻠﻚ اﻟﻰ اﻟﻤﺘﺠﺮ ﺧﻠﺎل \n <0>{{timer}}</0> اﻭ اﺿﻐﻂ ﺧﺮﻮﺟ ﻟﺘﺮﺟﻊ ﺣﺎﻟﺎ`,
    proceedWithExistingLogin: `من فضلك قم بتسجيل الدخول باستخدامه`,
    selectPlatform: "اختر تطبيق جوالك لإتمام طلبك",
    downloadFromStore: "اﺿﻐﻂ ﻋﻠﻰ ﺗﺤﻤﻴﻞ اﻟﺘﻄﺒﻴﻖ ﻟﺘﺬﻫﺐ اﻟﻰ اﻟﻤﺘﺠﺮ وﺗﻘﻮم ﺑﺘﻨﺰﻳﻠﻪ و ﺗﺤﺼﻞ ﻋﻠﻰ ﻃﻠﺒﻚ",
    confirmRejection: "اذا تريد رفض العقد من فضلك اضغط نعم.",
    srsCheckHaveNotPassed: "ﺳﻮف ﻳﺘﻢ ﺗﺤﻮﻳﻠﻚ إلى اﻟﻤﺘﺠﺮ ﺧﻠﺎل <0>{{timer}}</0> أو اﺿﻐﻂ ﺧﺮوج للعودة",
    srsCheckFailed: "عملية التحقق فشلت لأسباب تقنية. من فضلك أعد المحاولة.",
    srsCheckFinallyFailed: "عملية التحقق فشلت لأسباب تقنية. من فضلك أعد المحاولة.",
    exceededRegAttempts: "ﻟﻘﺪ ﺗﺨﻄﻴﺖ أﻗصى ﻋﺪد ﻣﺤﺎوﻟﺎت اﻟﺘﺴﺠﻴﻞ ﺑﻬﺬا اﻟﺮﻗﻢ اﻟﻘﻮﻣﻲ/ الإقامة",
    enterPhoneFromAbsher: "ﻣﻦ ﻓﻀﻠك أدﺧﻞ ﻧﻔﺲ ﺑﻴﺎﻧﺎﺗﻚ اﻟﻤﺴﺠﻠة ﻓﻲ ﻣﻨﺼة أﺑﺸﺮ ﻣﻦ أﺟﻞ اﻛﺘﻤﺎل اﻟﺘﺤﻘﻖ",
    successfulCallbackRequest: "سوف تستقبل مكالمة قريباً جداً",
    whereToGo: "من فضلك اختر اين تريد الذهاب.",
  },
  orderContent: {
    header: "المشتريات",
    subheader: {
      keyWithCount_one: "انت تؤكد <0> شراء منتج واحد </0> بقيمه كليه <1>{{sum}} </1>",
      keyWithCount_two: "انت تؤكد <0> شراء منتجين </0> بقيمه كليه <1>{{sum}} </1>",
      keyWithCount_few: `اﻧﺖ ﺗﺆﻛﺪ ﺷﺮاء <0/>{{itemsAmount}}<0> منتجات ﺑﻘﻴﻤﻪ كلية  <1>{{sum}}</1>`,
    },
    productsInCart: "المنتجات في العربه",
  },
  confirmationContent: {
    header: "ﻳﺠﺐ اﻟﺎﻧﺘﻈﺎر ٢٤ ﺳﺎﻋﻪ اﻟﺰاﻣﻴﻪ ﻟﻠﺎﺳﺘﻤﺮار",
    whatWillHappenNext: "ﻣﺎذا ﺳﻴﺘﻢ ﺑﻌﺪ ذﻟﻚ؟",
    phoneCall: "ﺳﺘﺴﺘﻠﻢ مكالمة هاتفية ﻣﻦ ٩٩ ﻟﻠﺘﺤﻘﻖ ﻣﻦ ﺗﺴﺠﻴﻠﻚ.",
    message: "ﺳﺘﺴﺘﻠﻢ رﺳﺎﻟﻪ ﻫﺎﺗﻔﻴﻪ ﺑﺎﻟﻤﻮاﻓﻘﻪ.",
    contract: "ستوقع العقد من خلال تطبيق ٩٩ او من الموقع بخطوات بسيطه.",
    nafithConfirm: "ستوافق على السند الاذني من نافذ.",
    transaction: "ﻃﻠﺒﻚ ﺳﻴﻜﻮن مكتملاً ﺑﻤﺠﺮد اﻧﺘﻬﺎء 24 ساعة.",
    installMobileApp:
      'يُمكنك إكمال توقيع العقد هنا أو من خلال تحميل التطبيق لإدارة مشترياتك وأقساطك عبر الضغط على "استمرار بالتطبيق".',
    warnings: {
      contractIsWaitingForACall:
        "جاري العمل على العقد! من فضلك انتظر مكالمتنا للتأكيد، ويُمكنك التقدُّم بطلب قرض آخر بعد الانتهاء من إجراءات التمويل الحالي.",
      contractIsWaitingForASigning:
        "جاري العمل على العقد! ﻣﻦ ﻓﻀﻠﻚ وﻗﻊ اﻟﻌﻘﺪ اﻟﻠﺬي ﻳﻨﺘﻈﺮك ﻓﻲ ﺗﻄﺒﻴﻖ ٩٩. ويُمكنك التقدُّم بطلب قرض آخر بعد الانتهاء من إجراءات التمويل الحالي.",
      contractIsSigned:
        "جاري العمل على العقد! ﻣﻦ ﻓﻀﻠﻚ اﻧﺘﻈﺮ إرﺳﺎل اﻟﻘﺮض اﻟﺤﺎﻟﻲ. ﻳﻤﻜﻨﻚ اﻟﺘﺤﻘﻖ ﻣﻦ ﻣﻮﻗﻔﻪ اﻟﺤﺎﻟﻲ ﺑﺎﺳﺘﺨﺪام ﺗﻄﺒﻴﻖ ٩٩. ويُمكنك التقدُّم بطلب قرض آخر بعد الانتهاء من إجراءات التمويل الحالي.",
    },
  },
  employmentDetails: {
    consent: "أوافق على طلب بياناتي من مكتب سمة للمعلومات الائتمانية.",
    fullConsent: "نص الموافقة",
    consentDialogTitle: "نص الموافقة",
    consentDialogDescription:
      "الشركة السعودية للمعلومات الائتمانية (سمة):\nأقر أنا الموقع أدناه (وكيل /أو شخص/ مفوض، بالنيابة عن الشركة بموافقتي على تمكين وتزويد شركة كوارا للتمويل بأي معلومات تيطلبها لفتح و/أو تدقيق و/أو إدارة حساباتي\n وتسهيلاتي. كما أفوضهم بجمع كافة البيانات اللازمة أو التي تخصني، أو تخص حسابي، أو التسهيلات اللاحقة، من الشركة السعودية للمعلومات الائتمانية (سمة) كما أتعهد بالإفصاح لشركة (سمة (بموجب اتفاقية مشاركة المعلومات) عن كافة\n البيانات والمعلومات أو لأي جهة يقرها البنك المركزي السعودي. (ساما).agency approved by Saudi Central Bank (SAMA)",
  },
  newOfferCalculator: {
    requestAmount: "المبلغ المطلوب",
    description: "اختر عرض التمويل الأنسب",
    sarPerMonth: "ريال/شهر",
  },
  offerDetails: {
    totalAmount: "المبلغ الإجمالي",
    description: "تفاصيل عرض التمويل",
    requested_amount: "المبلغ المطلوب",
    profit: "الربح",
    tenure: "الفترة",
    monthly_payment: "الدفع الشهري",
    interest_rate: "نسبة الفائدة",
  },
};
