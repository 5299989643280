import { ReactComponent as InfoIcon } from "@assets/icons/popup_error_icon.svg";

export const privateSectorVerificationFailedDialog = {
  title: "dialogTitle.privateSectorVerificationFailed",
  description: "dialogContent.contactForMoreOptions",
  agreeText: "buttons.selectSector",
  cancelText: "buttons.backToStore",
  icon: <InfoIcon />,
};

export const governmentSectorVerificationFailedDialog = {
  title: "dialogTitle.governmentSectorVerificationFailed",
  description: "dialogContent.contactForMoreOptions",
  agreeText: "buttons.selectSector",
  cancelText: "buttons.backToStore",
  icon: <InfoIcon />,
};

export const ACCOMMODATION_TYPES = {
  ownHouse: {
    label: "labels.ownHouse",
    key: "labels.ownHouse",
  },
  rental: {
    label: "labels.rental",
    key: "labels.rental",
  },
};
