import { IOTPInfoResponse } from "@appTypes/responses";

import { IAxiosResponseWithError, network } from "./network";

type useGetOTPStatusReturnType = [getNewOTPCode: () => Promise<IAxiosResponseWithError<IOTPInfoResponse>>];

export const useGenerateOTP = (): useGetOTPStatusReturnType => {
  const getNewOTPCode = (): Promise<IAxiosResponseWithError<IOTPInfoResponse>> => {
    return network.request<IOTPInfoResponse>("connect", "otp/verify", {
      method: "GET",
    });
  };

  return [getNewOTPCode];
};
