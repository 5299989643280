import { Grid, Box } from "@mui/material";
import { Typography } from "@ui-kit";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

import EPROMISSORY_TEMPLATE from "@assets/uiImages/epromissory_template.png";
import { StepperArabic } from "@components/StepperArabic";
import { ORDER_STATUSES } from "@constants/business/orderStatuses";
import { useGetContractInfoPooling } from "@utils/network/contractInfo/useGetCustomerInfoPooling";
import { COUNTDOWN_TIMES } from "@utils/services/Timer/Timer.service";

import { useStatusRouter } from "../hooks/useStatusRouter";
import { useSteps } from "../hooks/useSteps";

// Wrong step
export const EPromissory: FC = () => {
  const { t } = useTranslation();
  const { onGetContractStatus } = useStatusRouter(ORDER_STATUSES.SIGNED);
  const steps = useSteps();

  const onErrorPooling = () => true;

  const { startContractInfoPolling, stopPolling } = useGetContractInfoPooling();

  useEffect(() => {
    startContractInfoPolling(onGetContractStatus, onErrorPooling, COUNTDOWN_TIMES["2min"]);

    return () => stopPolling();
  }, []);

  return (
    <Box maxWidth="456px" margin="auto">
      <StepperArabic steps={steps} activeStep={2} />
      <Grid container>
        <Grid
          item
          margin="40px auto 0 auto"
          display="flex"
          justifyContent="center"
        >
          <img alt="Contract template" src={EPROMISSORY_TEMPLATE} />
        </Grid>
        <Grid item textAlign="center" mt="34px">
          <Typography variant="h3" fontWeight="bold" mb="16px">
            {t("checkout.title.ePromissory")}
          </Typography>
          <Typography variant="p1">{t("checkout.content.nafithInstruction")}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
