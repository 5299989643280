import { Typography, styled, Box, Grid } from "@mui/material";

export const RequestAmountTxt = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  [theme.breakpoints.down("sm")]: {},
}));

export const AmountTxt = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: "#425CC7",
  padding: "0px 20px",
  [theme.breakpoints.down("sm")]: {},
}));

export const CenterContainer = styled(Grid)(({ theme }) => ({
  margin: "4% 12%",
  [theme.breakpoints.down("sm")]: {
    margin: "4% 0",
  },
}));

export const MainBox = styled(Box)(({ theme }) => ({
  border: "2px solid #E9EBFC",
  padding: "30px 20px",
  borderRadius: 10,
  [theme.breakpoints.down("sm")]: {},
}));

export const BoxRow = styled(Grid)(({ theme }) => ({
  display: "flex",
  borderBottom: "1px solid #EDEEEF",
  paddingBottom: 20,
  marginBottom: 20,
  justifyContent: "space-between",
  textAlign: "start",
  [theme.breakpoints.down("sm")]: {},
}));

export const BoxLastRow = styled(Grid)(({ theme }) => ({
  display: "flex",
  borderBottom: "1px solid #EDEEEF",
  paddingBottom: 0,
  marginBottom: 20,
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {},
}));
