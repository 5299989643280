import { FC } from "react";
import { Switch } from "react-router-dom";

import { PrivateRoute } from "@pages/AppRouter/PrivateRoute";

import { CanceledFromMerchant } from "./CanceledFromMerchant";
import { Completed } from "./Completed";
import { ContractExpired } from "./ContractExpired";
import { ContractSigned } from "./ContractSigned";
import { DeclinedByUser } from "./DeclinedByUser";
import { DidntMeetQuaraCriteria } from "./DidntMeetQuaraCriteria";
import { EPromissory } from "./EPromissory";
import { Late } from "./Late";
import { LoanActivated } from "./LoanActivated";
import { SignContract } from "./SignContract.tsx";
import { WaitingConfirmation } from "./WaitingConfirmation";
import { WaitingForSigning } from "./WaitingForSigning";
import { WebCheckoutInitialStep } from "./WebCheckoutInitialState";
import { webCheckoutPageMap } from "./constants/pageMap";

export const WebCheckoutRouter: FC = () => {
  return (
    <Switch>
      <PrivateRoute path={webCheckoutPageMap.WaitingConfirmation.path}>
        <WaitingConfirmation />
      </PrivateRoute>
      <PrivateRoute path={webCheckoutPageMap.DidntMeetQuaraCriteria.path}>
        <DidntMeetQuaraCriteria />
      </PrivateRoute>
      <PrivateRoute path={webCheckoutPageMap.WaitingForSigning.path}>
        <WaitingForSigning />
      </PrivateRoute>
      <PrivateRoute path={webCheckoutPageMap.SignContract.path}>
        <SignContract />
      </PrivateRoute>
      <PrivateRoute path={webCheckoutPageMap.DeclinedByUser.path}>
        <DeclinedByUser />
      </PrivateRoute>
      <PrivateRoute path={webCheckoutPageMap.ContractExpired.path}>
        <ContractExpired />
      </PrivateRoute>
      <PrivateRoute path={webCheckoutPageMap.Completed.path}>
        <Completed />
      </PrivateRoute>
      <PrivateRoute path={webCheckoutPageMap.CanceledFromMerchant.path}>
        <CanceledFromMerchant />
      </PrivateRoute>
      <PrivateRoute path={webCheckoutPageMap.Late.path}>
        <Late />
      </PrivateRoute>
      <PrivateRoute path={webCheckoutPageMap.LoanActivated.path}>
        <LoanActivated />
      </PrivateRoute>
      <PrivateRoute path={webCheckoutPageMap.ContractSigned.path}>
        <ContractSigned />
      </PrivateRoute>
      <PrivateRoute path={webCheckoutPageMap.EPromissory.path}>
        <EPromissory />
      </PrivateRoute>
      <PrivateRoute path={webCheckoutPageMap.InitialStep.path}>
        <WebCheckoutInitialStep />
      </PrivateRoute>
    </Switch>
  );
};
